// src/components/TokenList.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
  Collapse,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  Box,
  Grid,
  Pagination,
  Skeleton,
  Divider,
  Button,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import {
  Search as SearchIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import TradingViewChart from './tradingviewchart'; // Corrected the import path to match the component name
import './tokenlist_test.css';

const TokenList = () => {
  const [tokens, setTokens] = useState([]);
  const [displayedTokens, setDisplayedTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expandedToken, setExpandedToken] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterDEX, setFilterDEX] = useState('all');
  const [sortOption, setSortOption] = useState('highestTVL'); // Default sorting
  const [darkMode, setDarkMode] = useState(true); // Dark mode enabled by default
  const [currentPage, setCurrentPage] = useState(1);
  const tokensPerPage = 10;

  // State to track which sections within the expanded token are open
  const [expandedSections, setExpandedSections] = useState({}); // { tokenId: { elexiumPool: boolean, ayinPool: boolean } }

  // Define MUI themes
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#FFD700',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#ffffff',
        secondary: '#bdbdbd',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#FFD700',
      },
      background: {
        default: '#f5f5f5',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
        secondary: '#555555',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  // Persist dark mode preference in localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get(
          'https://dohcloud.azurewebsites.net/api_test_transactions'
        );
  
        // Remove the logic that fetches and adds totalSupply
        setTokens(response.data);
        setDisplayedTokens(response.data);
      } catch (err) {
        console.error('Error fetching token data:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTokens();
  }, []);
  



  // Handle Search, Filter, and Sort functionalities
  useEffect(() => {
    let filtered = tokens.filter((token) => {
      const query = searchQuery.toLowerCase();
      return (
        token.name.toLowerCase().includes(query) ||
        token.symbol.toLowerCase().includes(query)
      );
    });

    // Apply DEX Filter
    if (filterDEX === 'ayin') {
      filtered = filtered.filter((token) => token.ayinPool);
    } else if (filterDEX === 'elexium') {
      filtered = filtered.filter((token) => token.elexiumPool);
    } else if (filterDEX === 'both') {
      filtered = filtered.filter(
        (token) => token.ayinPool && token.elexiumPool
      );
    }

    // Apply Sorting
    if (sortOption === 'highestTVL') {
      filtered.sort((a, b) => {
        const tvlA = Math.max(
          parseFloat(a.elexiumPool?.tvl || 0),
          parseFloat(a.ayinPool?.tvl || 0)
        );
        const tvlB = Math.max(
          parseFloat(b.elexiumPool?.tvl || 0),
          parseFloat(b.ayinPool?.tvl || 0)
        );
        return tvlB - tvlA;
      });
    }

    setDisplayedTokens(filtered);
    setCurrentPage(1); // Reset to first page on filter/sort
    setExpandedToken(null); // Collapse any expanded row when filtering/sorting
    setExpandedSections({}); // Reset expanded sections
  }, [searchQuery, filterDEX, sortOption, tokens]);

  // Toggle Dark Mode
  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  // Handle Pagination Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Helper function to format addresses
  const formatAddress = (address) => (
    <a
      href={`https://explorer.alephium.org/addresses/${address}`}
      target="_blank"
      rel="noopener noreferrer"
      className="address-link"
      id={`address-${address}`} // Adding unique ID for the address link
      aria-label={`Address ${address}`}
    >
      {address.slice(0, 6)}...{address.slice(-4)}
    </a>
  );

  // Determine which DEX the token is part of
  const determineDEX = (token) => {
    const dexList = [];
    if (token.elexiumPool) dexList.push('Elexium DEX');
    if (token.ayinPool) dexList.push('AYIN DEX');
    return dexList.length > 0 ? dexList.join(', ') : 'Unknown DEX';
  };

  // Function to calculate aggregated metrics
  const calculateMetrics = (token) => {
    let totalVolume = 0;
    let totalTVL = 0;
    let prices = [];
    let apr = 'N/A';

    if (token.elexiumPool) {
      totalVolume += parseFloat(token.elexiumPool.volume) || 0;
      totalTVL += parseFloat(token.elexiumPool.tvl) || 0;
      if (token.elexiumPool.onchainPrice) {
        prices.push(parseFloat(token.elexiumPool.onchainPrice));
      }
      if (token.elexiumPool.gauge && token.elexiumPool.gauge.apr) {
        apr = `${(parseFloat(token.elexiumPool.gauge.apr) * 100).toFixed(2)}%`; // Convert to percentage
      }
    }

    if (token.ayinPool) {
      totalVolume += parseFloat(token.ayinPool.volume) || 0;
      totalTVL += parseFloat(token.ayinPool.tvl) || 0;
      if (token.ayinPool.onchainPrice) {
        prices.push(parseFloat(token.ayinPool.onchainPrice));
      }
    }

    const averagePrice =
      prices.length > 0
        ? (prices.reduce((a, b) => a + b, 0) / prices.length).toFixed(2)
        : 'N/A';

    let onchainPrice = 'N/A';


// Check for 'ALPH', 'EXY', or 'ABX' symbol and use token0's on-chain price
if (
  token.symbol.toLowerCase() === 'alph' ||
  token.symbol.toLowerCase() === 'exy' ||
  token.symbol.toLowerCase() === 'abx'
) {
  // For Alephium (ALPH), EXY, and ABX tokens, use token0's on-chain price
  if (token.elexiumPool?.token0?.onchainPrice) {
      onchainPrice = `$${parseFloat(token.elexiumPool.token0.onchainPrice).toFixed(4)}`;
  }
} else {
  // For other tokens, use token1's on-chain price
  if (token.elexiumPool?.token1?.onchainPrice) {
      onchainPrice = `$${parseFloat(token.elexiumPool.token1.onchainPrice).toFixed(4)}`;
  } else if (token.ayinPool?.token1?.onchainPrice) {
      onchainPrice = `$${parseFloat(token.ayinPool.token1.onchainPrice).toFixed(4)}`;
  }
}


    return {
      totalVolume: totalVolume > 0 ? totalVolume.toLocaleString() : 'N/A',
      totalTVL: totalTVL > 0 ? totalTVL.toLocaleString() : 'N/A',
      averagePrice,
      apr,
      onchainPrice,
    };
  };

  // Function to determine TradingView symbol
  const getTradingViewSymbol = (token) => {
    // Example implementation based on token symbol
    // You might need to adjust this logic based on your actual data
    const symbolMap = {
      alph: 'MEXC:ALPHUSDT',
      exy: 'BINANCE:EXYUSDT',
      // Add more mappings as needed
    };

    return symbolMap[token.symbol.toLowerCase()] || 'BINANCE:BTCUSDT'; // Default to BTC if not found
  };

  // Calculate tokens to display based on current page
  const indexOfLastToken = currentPage * tokensPerPage;
  const indexOfFirstToken = indexOfLastToken - tokensPerPage;
  const currentTokens = displayedTokens.slice(indexOfFirstToken, indexOfLastToken);
  const totalPages = Math.ceil(displayedTokens.length / tokensPerPage);

  // Handle main row click to expand/collapse token details
  const handleRowClick = (tokenId) => {
    setExpandedToken((prev) => (prev === tokenId ? null : tokenId));
  };

  // Handle section button click within token details
  const handleSectionToggle = (tokenId, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [tokenId]: {
        ...prev[tokenId],
        [section]: !prev[tokenId]?.[section],
      },
    }));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className={`token-list-container ${darkMode ? 'dark' : 'light'}`}>
        {/* AppBar */}
        <AppBar
          position="static"
          sx={{
            backgroundColor: darkMode ? '#000000' : undefined, // Black in dark mode
            color: darkMode ? '#FFD700' : undefined, // Gold text in dark mode
          }}
          elevation={0} // Flat look
        >
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Alephium Tokens Dashboard
            </Typography>
            <Tooltip title="Toggle Dark Mode">
              <IconButton
                onClick={handleDarkModeToggle}
                color="inherit"
                aria-label="Toggle dark mode"
              >
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        {/* Main Content */}
        <Container style={{ padding: '2rem 0' }}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'stretch', md: 'center' }}
            mb={3}
            gap={2}
          >
            {/* Search Bar */}
            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Search by name or symbol"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      style={{ marginRight: '0.5rem', color: darkMode ? '#FFD700' : '#000' }}
                    />
                  ),
                  style: { color: darkMode ? '#FFD700' : '#000' },
                }}
                inputProps={{
                  'aria-label': 'Search Tokens',
                }}
              />
            </FormControl>

            {/* DEX Filter */}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="dex-filter-label" style={{ color: darkMode ? '#FFD700' : undefined }}>
                Filter by DEX
              </InputLabel>
              <Select
                labelId="dex-filter-label"
                value={filterDEX}
                onChange={(e) => setFilterDEX(e.target.value)}
                label="Filter by DEX"
                style={{ color: darkMode ? '#FFD700' : undefined }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkMode ? '#1e1e1e' : '#fff',
                      color: darkMode ? '#FFD700' : '#000',
                    },
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="ayin">AYIN DEX</MenuItem>
                <MenuItem value="elexium">Elexium DEX</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>

            {/* Sort Option */}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="sort-option-label" style={{ color: darkMode ? '#FFD700' : undefined }}>
                Sort by
              </InputLabel>
              <Select
                labelId="sort-option-label"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                label="Sort by"
                style={{ color: darkMode ? '#FFD700' : undefined }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkMode ? '#1e1e1e' : '#fff',
                      color: darkMode ? '#FFD700' : '#000',
                    },
                  },
                }}
              >
                <MenuItem value="highestTVL">Highest TVL</MenuItem>
                <MenuItem value="none">None</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Token Table */}
          {loading ? (
            <Box display="flex" flexDirection="column" gap={2} mt={5}>
              {[...Array(5)].map((_, index) => (
                <Skeleton variant="rectangular" height={60} key={index} />
              ))}
            </Box>
          ) : error ? (
            <Typography variant="h6" color="error" align="center">
              <ErrorIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
              Failed to load token data.
            </Typography>
          ) : displayedTokens.length === 0 ? (
            <Typography variant="h6" align="center">
              No tokens found.
            </Typography>
          ) : (
            <Box className="responsive-table-container">
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: darkMode ? '#121212' : '#fff',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  borderRadius: '8px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow className="token-table-header">
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell align="right" className="volume-column">
                        Volume
                      </TableCell>
                      <TableCell align="right" className="tvl-column">
                        TVL
                      </TableCell>
                      <TableCell align="right" className="apr-column">
                        APR
                      </TableCell>
                      <TableCell align="right" className="price-column">
                        On-Chain Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTokens.map((token) => {
                      const metrics = calculateMetrics(token);
                      const isExpanded = expandedToken === token.id;
                      const symbol = getTradingViewSymbol(token);

                      return (
                        <React.Fragment key={token.id}>
                          {/* Main Token Row */}
                          <TableRow
                            hover
                            onClick={() => handleRowClick(token.id)}
                            className="token-table-row"
                            sx={{
                              cursor: 'pointer',
                              transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                backgroundColor: darkMode
                                  ? 'rgba(255, 215, 0, 0.05)'
                                  : '#f5f5f5',
                                transform: 'scale(1.02)',
                                boxShadow: darkMode
                                  ? '0 4px 8px rgba(255, 215, 0, 0.2)'
                                  : '0 4px 8px rgba(0,0,0,0.1)',
                              },
                            }}
                          >
                            <TableCell>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering row click
                                  handleRowClick(token.id);
                                }}
                                aria-label={isExpanded ? 'Collapse details' : 'Expand details'}
                                className="expand-icon"
                                id={`expand-button-${token.id}`} // Adding unique ID for the expand button
                              >
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <Avatar
                                  src={token.logoURI}
                                  alt={`${token.name} logo`}
                                  className="token-avatar"
                                  loading="lazy"
                                  id={`avatar-${token.id}`} // Adding unique ID for the avatar
                                />
                                <Box ml={2}>
                                  <Typography variant="subtitle1" className="token-name" id={`token-name-${token.id}`}>
                                    {token.name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" id={`token-symbol-${token.id}`}>
                                    {token.symbol.toUpperCase()}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="right" className="metric volume-column" id={`volume-${token.id}`}>
                              {metrics.totalVolume}
                            </TableCell>
                            <TableCell align="right" className="metric tvl-column" id={`tvl-${token.id}`}>
                              {metrics.totalTVL}
                            </TableCell>
                            <TableCell align="right" className="metric apr-column" id={`apr-${token.id}`}>
                              {metrics.apr}
                            </TableCell>
                            <TableCell align="right" className="metric price-column" id={`price-${token.id}`}>
                              {metrics.onchainPrice}
                            </TableCell>
                          </TableRow>

                          {/* Collapsible Details Row */}
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6} // Adjust colspan based on the number of columns
                            >
                              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box className="collapse-content" p={2} id={`collapse-content-${token.id}`}>
                                  {/* Organized Token Information */}
                                  <Box mb={2} id={`token-info-${token.id}`}>
                                    <Typography variant="h6" gutterBottom>
                                      <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                      Token Information
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`decimals-${token.id}`}>
                                          <strong>Decimals:</strong> {token.decimals}
                                        </Typography>
                                      </Grid>
                                      {token.description && (
                                        <Grid item xs={12}>
                                          <Typography variant="body2" id={`description-${token.id}`}>
                                            <strong>Description:</strong> {token.description}
                                          </Typography>
                                        </Grid>
                                      )}
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`dex-${token.id}`}>
                                          <strong>DEX:</strong> {determineDEX(token)}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`token-id-${token.id}`}>
                                          <strong>Token ID:</strong> {formatAddress(token.id)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>

                                  {/* Pool Detail Buttons */}
                                  <Box mb={2} display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                                    {/* Elexium Pool Details Button */}
                                    {token.elexiumPool && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={
                                          expandedSections[token.id]?.elexiumPool ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        }
                                        onClick={() => handleSectionToggle(token.id, 'elexiumPool')}
                                        sx={{
                                          backgroundColor: darkMode ? '#FFD700' : 'primary.main',
                                          color: darkMode ? '#000' : '#fff',
                                          '&:hover': {
                                            backgroundColor: darkMode ? '#FFC700' : 'primary.dark',
                                          },
                                        }}
                                        id={`elexium-pool-button-${token.id}`} // Adding unique ID for the button
                                        aria-expanded={expandedSections[token.id]?.elexiumPool ? 'true' : 'false'}
                                        aria-controls={`elexium-pool-section-${token.id}`}
                                      >
                                        {expandedSections[token.id]?.elexiumPool ? 'Hide Elexium Pool Details' : 'Elexium Pool Details'}
                                      </Button>
                                    )}

                                    {/* Ayin Pool Details Button */}
                                    {token.ayinPool && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={
                                          expandedSections[token.id]?.ayinPool ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        }
                                        onClick={() => handleSectionToggle(token.id, 'ayinPool')}
                                        sx={{
                                          backgroundColor: darkMode ? '#FFD700' : 'primary.main',
                                          color: darkMode ? '#000' : '#fff',
                                          '&:hover': {
                                            backgroundColor: darkMode ? '#FFC700' : 'primary.dark',
                                          },
                                        }}
                                        id={`ayin-pool-button-${token.id}`} // Adding unique ID for the button
                                        aria-expanded={expandedSections[token.id]?.ayinPool ? 'true' : 'false'}
                                        aria-controls={`ayin-pool-section-${token.id}`}
                                      >
                                        {expandedSections[token.id]?.ayinPool ? 'Hide Ayin Pool Details' : 'Ayin Pool Details'}
                                      </Button>
                                    )}
                                  </Box>

                                  {/* Elexium Pool Detail Section */}
                                  {token.elexiumPool && (
                                    <Collapse
                                      in={expandedSections[token.id]?.elexiumPool}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      {/* Elexium Pool Information */}
                                      <Box mb={2} id={`elexium-pool-section-${token.id}`}>
                                        <Typography variant="h6" gutterBottom>
                                          <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                          Elexium Pool Information
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={1}>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-address-${token.id}`}>
                                              <strong>Pool Address:</strong> {formatAddress(token.elexiumPool.poolAddress)}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-id-${token.id}`}>
                                              <strong>Pool ID:</strong> {token.elexiumPool.id}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-stable-${token.id}`}>
                                              <strong>Stable:</strong> {token.elexiumPool.stable ? 'Yes' : 'No'}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-volume-${token.id}`}>
                                              <strong>Volume:</strong> {parseFloat(token.elexiumPool.volume).toLocaleString()}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-tvl-${token.id}`}>
                                              <strong>TVL:</strong> {parseFloat(token.elexiumPool.tvl).toLocaleString()}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-onchain-price-${token.id}`}>
                                              <strong>On-Chain Price:</strong>{' '}
                                              {token.elexiumPool.onchainPrice
                                                ? `$${parseFloat(token.elexiumPool.onchainPrice).toFixed(2)}`
                                                : 'N/A'}
                                            </Typography>
                                          </Grid>
                                          {/* APR Information */}
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`elexium-pool-apr-${token.id}`}>
                                              <strong>APR:</strong>{' '}
                                              {token.elexiumPool.gauge?.apr
                                                ? `${(parseFloat(token.elexiumPool.gauge.apr) * 100).toFixed(2)}%`
                                                : 'N/A'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>

                                      {/* LP Token Information */}
                                      {token.elexiumPool.lpToken && (
                                        <Box mb={2} id={`lp-token-section-${token.id}`}>
                                          <Typography variant="h6" gutterBottom>
                                            <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                            LP Token Information
                                          </Typography>
                                          <Divider sx={{ mb: 2 }} />
                                          <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                              <Typography variant="body2" id={`lp-token-id-${token.id}`}>
                                                <strong>ID:</strong> {formatAddress(token.elexiumPool.lpToken.id)}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography variant="body2" id={`lp-token-name-${token.id}`}>
                                                <strong>Name:</strong> {token.elexiumPool.lpToken.name}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )}

                                      {/* Organize Token0, Token1, and Gauge Information in a Single Row */}
                                      <Box mb={2} id={`token-gauge-info-${token.id}`}>
                                        <Grid container spacing={2}>
                                          {/* Token0 Information */}
                                          {token.elexiumPool.token0 && (
                                            <Grid item xs={12} md={4}>
                                              <Typography variant="h6" gutterBottom>
                                                <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                                Token0 Information
                                              </Typography>
                                              <Divider sx={{ mb: 2 }} />
                                              <Typography variant="body2" id={`token0-address-${token.id}`}>
                                                <strong>Address:</strong> {formatAddress(token.elexiumPool.token0.address)}
                                              </Typography>
                                              <Typography variant="body2" id={`token0-symbol-${token.id}`}>
                                                <strong>Symbol:</strong> {token.elexiumPool.token0.symbol}
                                              </Typography>
                                              <Typography variant="body2" id={`token0-name-${token.id}`}>
                                                <strong>Name:</strong> {token.elexiumPool.token0.name}
                                              </Typography>
                                              <Typography variant="body2" id={`token0-onchain-price-${token.id}`}>
                                                <strong>On-Chain Price:</strong>{' '}
                                                {token.elexiumPool.token0.onchainPrice
                                                  ? `$${parseFloat(token.elexiumPool.token0.onchainPrice).toFixed(4)}`
                                                  : 'N/A'}
                                              </Typography>
                                            </Grid>
                                          )}

                                          {/* Token1 Information */}
                                          {token.elexiumPool.token1 && (
                                            <Grid item xs={12} md={4}>
                                              <Typography variant="h6" gutterBottom>
                                                <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                                Token1 Information
                                              </Typography>
                                              <Divider sx={{ mb: 2 }} />
                                              <Typography variant="body2" id={`token1-address-${token.id}`}>
                                                <strong>Address:</strong> {formatAddress(token.elexiumPool.token1.address)}
                                              </Typography>
                                              <Typography variant="body2" id={`token1-symbol-${token.id}`}>
                                                <strong>Symbol:</strong> {token.elexiumPool.token1.symbol}
                                              </Typography>
                                              <Typography variant="body2" id={`token1-name-${token.id}`}>
                                                <strong>Name:</strong> {token.elexiumPool.token1.name}
                                              </Typography>
                                              <Typography variant="body2" id={`token1-price-${token.id}`}>
                                                <strong>Price:</strong>{' '}
                                                {token.elexiumPool.token1.onchainPrice
                                                  ? `$${parseFloat(token.elexiumPool.token1.onchainPrice).toFixed(4)}`
                                                  : 'N/A'}
                                              </Typography>
                                            </Grid>
                                          )}

                                          {/* Gauge Information */}
                                          {token.elexiumPool.gauge && (
                                            <Grid item xs={12} md={4}>
                                              <Typography variant="h6" gutterBottom>
                                                <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                                Gauge Information
                                              </Typography>
                                              <Divider sx={{ mb: 2 }} />
                                              <Typography variant="body2" id={`gauge-address-${token.id}`}>
                                                <strong>Gauge Address:</strong> {formatAddress(token.elexiumPool.gauge.address)}
                                              </Typography>
                                              <Typography variant="body2" id={`gauge-apr-${token.id}`}>
                                                <strong>APR:</strong>{' '}
                                                {token.elexiumPool.gauge.apr
                                                  ? `${(parseFloat(token.elexiumPool.gauge.apr) * 100).toFixed(2)}%`
                                                  : 'N/A'}
                                              </Typography>
                                              <Typography variant="body2" id={`gauge-period-finish-${token.id}`}>
                                                <strong>Period Finish:</strong>{' '}
                                                {token.elexiumPool.gauge.periodFinish
                                                  ? new Date(token.elexiumPool.gauge.periodFinish).toLocaleString()
                                                  : 'N/A'}
                                              </Typography>
                                              <Typography variant="body2" id={`gauge-internal-bribe-${token.id}`}>
                                                <strong>Internal Bribe Address:</strong> {formatAddress(token.elexiumPool.gauge.internalBribeAddress)}
                                              </Typography>
                                              <Typography variant="body2" id={`gauge-external-bribe-${token.id}`}>
                                                <strong>External Bribe Address:</strong> {formatAddress(token.elexiumPool.gauge.externalBribeAddress)}
                                              </Typography>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  )}

                                  {/* Ayin Pool Detail Section */}
                                  {token.ayinPool && (
                                    <Collapse
                                      in={expandedSections[token.id]?.ayinPool}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box mb={2} id={`ayin-pool-section-${token.id}`}>
                                        <Typography variant="h6" gutterBottom>
                                          <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                          Ayin Pool Information
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={1}>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-factory-${token.id}`}>
                                              <strong>Factory:</strong> {formatAddress(token.ayinPool.factory)}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-address-${token.id}`}>
                                              <strong>Pool Address:</strong> {formatAddress(token.ayinPool.address)}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-name-${token.id}`}>
                                              <strong>Name:</strong> {token.ayinPool.name || 'N/A'}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-symbol-${token.id}`}>
                                              <strong>Symbol:</strong> {token.ayinPool.symbol || 'N/A'}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-decimals-${token.id}`}>
                                              <strong>Decimals:</strong> {token.ayinPool.decimals}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="body2" id={`ayin-pool-total-supply-${token.id}`}>
                                              <strong>Total Supply:</strong> {token.ayinPool.totalSupply}
                                            </Typography>
                                          </Grid>
                                          {token.ayinPool.description && (
                                            <Grid item xs={12}>
                                              <Typography variant="body2" id={`ayin-pool-description-${token.id}`}>
                                                <strong>Description:</strong> {token.ayinPool.description}
                                              </Typography>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Box>

                                      {/* Ayin Pool Tokens */}
                                      {token.ayinPool.token0 || token.ayinPool.token1 ? (
                                        <Box mb={2} id={`ayin-pool-tokens-section-${token.id}`}>
                                          <Typography variant="h6" gutterBottom>
                                            <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                            Ayin Pool Tokens
                                          </Typography>
                                          <Divider sx={{ mb: 2 }} />
                                          <Grid container spacing={1}>
                                            {/* Ayin Pool Token0 */}
                                            {token.ayinPool.token0 && (
                                              <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" id={`ayin-token0-address-${token.id}`}>
                                                  <strong>Token0 Address:</strong> {formatAddress(token.ayinPool.token0.address)}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token0-symbol-${token.id}`}>
                                                  <strong>Symbol:</strong> {token.ayinPool.token0.symbol}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token0-name-${token.id}`}>
                                                  <strong>Name:</strong> {token.ayinPool.token0.name}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token0-onchain-price-${token.id}`}>
                                                  <strong>On-Chain Price:</strong>{' '}
                                                  {token.ayinPool.token0.onchainPrice
                                                    ? `$${parseFloat(token.ayinPool.token0.onchainPrice).toFixed(2)}`
                                                    : 'N/A'}
                                                </Typography>
                                              </Grid>
                                            )}

                                            {/* Ayin Pool Token1 */}
                                            {token.ayinPool.token1 && (
                                              <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" id={`ayin-token1-address-${token.id}`}>
                                                  <strong>Token1 Address:</strong> {formatAddress(token.ayinPool.token1.address)}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token1-symbol-${token.id}`}>
                                                  <strong>Symbol:</strong> {token.ayinPool.token1.symbol}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token1-name-${token.id}`}>
                                                  <strong>Name:</strong> {token.ayinPool.token1.name}
                                                </Typography>
                                                <Typography variant="body2" id={`ayin-token1-onchain-price-${token.id}`}>
                                                  <strong>On-Chain Price:</strong>{' '}
                                                  {token.ayinPool.token1.onchainPrice
                                                    ? `$${parseFloat(token.ayinPool.token1.onchainPrice).toFixed(2)}`
                                                    : 'N/A'}
                                                </Typography>
                                              </Grid>
                                            )}
                                          </Grid>
                                        </Box>
                                      ) : (
                                        <Typography variant="body2" color="textSecondary">
                                          No Ayin Pool tokens available.
                                        </Typography>
                                      )}
                                    </Collapse>
                                  )}

                                  {/* TradingView Chart */}
                                  <Box mb={2} id={`trading-chart-${token.id}`}>
                                    <Typography variant="h6" gutterBottom>
                                      <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                      Trading Chart
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    <TradingViewChart symbol={symbol} />
                                  </Box>
                                </Box>
                              </Collapse> {/* Closing Collapse for main token details */}
                            </TableCell> {/* Closing TableCell */}
                          </TableRow> {/* Closing TableRow for collapsible details */}
                        </React.Fragment>
                      );
                    })}
                  </TableBody> {/* Closing TableBody */}
                </Table> {/* Closing Table */}
              </TableContainer> {/* Closing TableContainer */}

              {/* Pagination */}
              {totalPages > 1 && (
                <Box display="flex" justifyContent="center" mt={3}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    id="pagination-component" // Adding unique ID for the pagination
                    aria-label="Token list pagination"
                  />
                </Box>
              )}
            </Box>
          )}
        </Container> {/* Closing Container */}
      </div> {/* Closing the main div */}
    </ThemeProvider>
  ); // Closing the TokenList component
}; // Closing the TokenList function

export default TokenList; // Exporting the TokenList component
