import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import AyinBuyLogo from '../assets/icons/ayin_buy.png';
import AyinSellLogo from '../assets/icons/ayin_sell.png';
import ExBuyLogo from '../assets/icons/ex_buy.png';
import ExSellLogo from '../assets/icons/ex_sell.png';
import DefaultLogo from '../assets/icons/doh_token.png';

const TransactionTable = ({ transactions = [] }) => {
  const [hoveredRowId, setHoveredRowId] = useState(null);

  // Adjust transaction types for specific sources
  const adjustedTransactions = useMemo(() => {
    return transactions.map(tx =>
      ['ex', 'elexium'].includes(tx.source.toLowerCase())
        ? { ...tx, type: tx.type.toLowerCase() === 'buy' ? 'sell' : 'buy' }
        : tx
    );
  }, [transactions]);

  // Calculate the daily high quantities for "buy" and "sell"
  const { dailyHighBuy, dailyHighSell } = useMemo(() => {
    let maxBuy = 0;
    let maxSell = 0;

    adjustedTransactions.forEach(tx => {
      if (tx.type.toLowerCase() === 'buy') {
        maxBuy = Math.max(maxBuy, tx.target_volume || 0);
      } else if (tx.type.toLowerCase() === 'sell') {
        maxSell = Math.max(maxSell, tx.target_volume || 0);
      }
    });

    return { dailyHighBuy: maxBuy, dailyHighSell: maxSell };
  }, [adjustedTransactions]);

  const formatPrice = price => {
    if (price > 99999) return 'N/A';

    const priceStr = price.toFixed(8);
    const [_, decimals] = priceStr.split('.');
    const leadingZeros = decimals.match(/^0+/)?.[0].length || 0;

    if (leadingZeros > 2) {
      const remaining = decimals.slice(leadingZeros, leadingZeros + 2);
      return `.${remaining} ℵ`;
    }

    if (price >= 100000) return `${(price / 1000).toFixed(2)}k ℵ`;
    if (price >= 1000 || price >= 1) return `${price.toFixed(2)} ℵ`;

    return `.${price.toFixed(4).split('.')[1]} ℵ`;
  };

  const formatLargeNumber = num => {
    if (num >= 1e9) return `${(num / 1e9).toFixed(1)}b`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(1)}m`;
    if (num >= 1e3) return `${(num / 1e3).toFixed(1)}k`;
    return `${num.toFixed(1)}`;
  };

  const formatTimeAgo = timestamp => {
    const diff = Math.floor((Date.now() - Number(timestamp)) / 1000);
    if (diff < 60) return `${diff}s`;
    if (diff < 3600) return `${Math.floor(diff / 60)}m`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h`;
    if (diff < 604800) return `${Math.floor(diff / 86400)}d`;
    return `${Math.floor(diff / 604800)}w`;
  };

  const renderDexLogo = (source, type) => {
    const logos = {
      buy: ['mobula', 'ayin'].includes(source.toLowerCase()) ? AyinBuyLogo :
            ['ex', 'elexium'].includes(source.toLowerCase()) ? ExBuyLogo : DefaultLogo,
      sell: ['mobula', 'ayin'].includes(source.toLowerCase()) ? AyinSellLogo :
             ['ex', 'elexium'].includes(source.toLowerCase()) ? ExSellLogo : DefaultLogo,
    };
    return <img src={logos[type.toLowerCase()]} alt={`${source} ${type}`} style={{ width: 20, height: 20 }} />;
  };

  const handleRowClick = txId => {
    if (txId) window.open(`https://explorer.alephium.org/transactions/${txId}`, '_blank');
  };

  return (
    <div style={{ backgroundColor: '#000', borderRadius: '8px', overflow: 'hidden', maxWidth: '100%' }}>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.9em', color: '#fff' }}>
          <thead style={{ position: 'sticky', top: 0, backgroundColor: '#000', zIndex: 1 }}>
            <tr>
              {['Type', 'Qty', 'Price', 'ℵ', 'Time'].map((header, idx) => (
                <th key={idx} style={{
                  padding: '6px 10px',
                  textAlign: 'center',
                  color: '#ffd700',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #ffd700', // Yellow bottom border for column titles
                }}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {adjustedTransactions.length === 0 ? (
              <tr>
                <td colSpan="5" style={{ padding: '10px', textAlign: 'center', color: '#999' }}>
                  No transactions available.
                </td>
              </tr>
            ) : (
              adjustedTransactions.map((tx, index) => {
                const isHovered = hoveredRowId === tx.tx_id;
                const isDailyHigh =
                  (tx.type.toLowerCase() === 'buy' && tx.target_volume === dailyHighBuy) || 
                  (tx.type.toLowerCase() === 'sell' && tx.target_volume === dailyHighSell);

                return (
                  <tr
                    key={tx.tx_id || index}
                    onClick={() => handleRowClick(tx.tx_id)}
                    onMouseEnter={() => setHoveredRowId(tx.tx_id)}
                    onMouseLeave={() => setHoveredRowId(null)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isHovered ? '#1e1e1e' : (index % 2 === 0 ? '#1a1a1a' : '#252525'),
                      borderTop: isDailyHigh ? '1px solid #ffd700' : 'none', // Yellow top border for daily highs
                      borderBottom: isHovered
                        ? '2px solid #ffd700'
                        : isDailyHigh
                        ? '1px solid #ffd700' // Yellow bottom border for daily highs
                        : '1px solid #444',
                      color: isHovered ? '#ffd700' : '#fff',
                      transition: 'background-color 0.3s, border 0.3s',
                    }}
                  >
                    <td style={{ padding: '6px 10px', textAlign: 'center' }}>
                      {renderDexLogo(tx.source, tx.type)}
                    </td>
                    <td style={{ padding: '6px 10px', textAlign: 'center', color: tx.type.toLowerCase() === 'buy' ? '#00ff00' : '#ff0000' }}>
                      {tx.target_volume ? formatLargeNumber(tx.target_volume) : 'N/A'}
                    </td>
                    <td style={{
                      padding: '6px 10px',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      color: isHovered ? '#ffd700' : '#fff',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      {tx.base_volume && tx.target_volume ? formatPrice(tx.base_volume / tx.target_volume) : 'N/A'}
                    </td>
                    <td style={{ padding: '6px 10px', textAlign: 'center', color: tx.type.toLowerCase() === 'buy' ? '#00ff00' : '#ff0000' }}>
                      {tx.base_volume ? formatLargeNumber(tx.base_volume) : 'N/A'}
                    </td>
                    <td style={{ padding: '6px 10px', textAlign: 'center', color: '#ffd700' }}>
                      {formatTimeAgo(tx.trade_timestamp)}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TransactionTable.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      base_volume: PropTypes.number,
      target_volume: PropTypes.number,
      trade_timestamp: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      tx_id: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TransactionTable;