import React, { useState, useEffect } from 'react';
import axios from 'axios';
import alphProLogo from '../assets/logos/alphpro.svg'; // Import Alph Pro logo
import dohLogo from '../assets/logos/DOH_OFFICIAL_CIRICLE.png'; // Import DOH logo
import { ANS } from "@alph-name-service/ans-sdk"; // Import ANS SDK
import './tokens.css'; // Use consolidated CSS
import PropTypes from 'prop-types';

const Wallet = ({ walletAddress }) => {
    const [balances, setBalances] = useState([]);
    const [nfts, setNfts] = useState([]); // State for NFTs
    const [tokenPrices, setTokenPrices] = useState({}); // State for token prices
    const [loadingBalances, setLoadingBalances] = useState(false);
    const [errorBalances, setErrorBalances] = useState(null);
    const [errorNfts, setErrorNfts] = useState(null);
    const [activeTab, setActiveTab] = useState('balances'); // Default view is Balances
    const [ansName, setAnsName] = useState(''); // State for ANS name
    const [ansUri, setAnsUri] = useState(''); // State for ANS image URI
    const [totalUSD, setTotalUSD] = useState(0); // State for total USD value

    // Sorting state for balances
    const [sortConfig, setSortConfig] = useState({ key: 'token', direction: 'ascending' });

    // Custom name mapping for special addresses (like Elexium LP and Ayin LP)
    const CUSTOM_NAMES = {
        "wwXHuyXVFBv3zxNf7N3g4D1t2HessF1bpNj6ydDQ1r1m": "ALPH/DOH EX LP",
        "27Ub32AhfC9ULKGKGUTdDGU2ehvUN55aLS4oU8nmW3x9M": "ALPH EX LP",
        "25GTexSvCdbTSewcHfKbVmJKRisZ9cEyJQaNwAgEo5RMy": "APAD EX LP",
        "22iqxjtK14yAD12bE4yiGfJL6XEcoKkMmFZ76DyWtg3wM": "AYIN EX LP",
        "285kUM2LojaKLDPmnc8XxT1azu1G9zR6jjmeEkewJaaWK": "USDT/USDC Stable EX LP",
        "zVRuf1edCyysNDy5nSXEdNcrCJCehfMVpD6kcWqDd9fd": "ALPH/USDT EX LP",
        "24LsfSVVpkFHWFDZpRduuro2WDab3XfmjfyYcAg43K2fq": "ALPH/TOP EX LP",
        "wLqG2MwkA6Y3U53v9sn7NgrcuQGvsNeqm6v6v8GxnGSb": "ALPH/ANS EX LP",
        "27oibfZEc2EcdUNxg8L6VshJ3R1WVCRqGAGuFHG6AMhkK": "GRUMPY/ALPH EX LP"
    };

    useEffect(() => {
        if (walletAddress) {
            fetchWalletInfo();
            fetchAnsProfile(walletAddress); // Fetch ANS profile for the wallet address
        }
    }, [walletAddress]);

    // Fetch wallet info (balances and NFTs)
    const fetchWalletInfo = async () => {
        setLoadingBalances(true);
        setErrorBalances(null);
        setErrorNfts(null);
        setBalances([]); // Clear previous balances
        setNfts([]); // Clear previous NFTs
        setTokenPrices({}); // Clear previous token prices
        setTotalUSD(0); // Reset total USD

        try {
            const response = await axios.get(
                `https://dohcloud.azurewebsites.net/api_GetWalletInfo?address=${walletAddress}`
            );

            const fetchedBalances = response.data.balances || [];
            setBalances(fetchedBalances);

            // Handle NFTs by filtering from balances
            const fetchedNfts = fetchedBalances.filter(item => item.nft) || [];
            setNfts(fetchedNfts);

            // Extract token addresses for price fetching
            const tokenAddresses = fetchedBalances
                .filter(b => b.token && b.token.address) // Filter out balances without a valid token
                .map(b => b.token.address);

            if (tokenAddresses.length === 0) return;

            // Fetch prices for all token addresses via Azure Function
            const pricePromises = tokenAddresses.map(addr => 
                axios.get(`https://dohcloud.azurewebsites.net/api_GetTokenPrice?address=${addr}`)
                    .then(resp => resp.data)
                    .catch(err => {
                        console.error(`Error fetching price for address ${addr}:`, err);
                        return null;
                    })
            );

            const priceResponses = await Promise.all(pricePromises);
            const pricesMap = {};

            priceResponses.forEach(resp => {
                if (resp && resp.symbol && resp.price !== undefined) {
                    pricesMap[resp.symbol] = resp.price;
                }
            });

            setTokenPrices(pricesMap);
        } catch (error) {
            setErrorBalances('Error fetching balances or token prices. Please try again.');
            setErrorNfts('Error fetching NFTs. Please try again.');
            console.error('Error fetching balances:', error);
        } finally {
            setLoadingBalances(false);
        }
    };

    // Fetch ANS Profile
    const fetchAnsProfile = async (address) => {
        try {
            const ans = new ANS('mainnet', true, "https://node.alphaga.app", "https://backend.mainnet.alephium.org");
            const profile = await ans.getProfile(address);
            if (profile && (profile.name || profile.imgUri)) {
                setAnsName(profile.name || '');
                setAnsUri(profile.imgUri || '');
            } else {
                setAnsName('');
                setAnsUri('');
            }
        } catch (error) {
            console.error('Error fetching ANS profile:', error);
            setAnsName('');
            setAnsUri('');
        }
    };

    // Handle custom names (Elexium and Ayin LP addresses)
    const getWalletName = (address) => {
        return CUSTOM_NAMES[address] || formatWalletAddress(address);
    };

    // Sorting function for balances
    const sortedBalances = React.useMemo(() => {
        return [...balances]
            .filter(item => item.token)
            .sort((a, b) => {
                const { key, direction } = sortConfig;
                const order = direction === 'ascending' ? 1 : -1;

                if (key === 'balance') {
                    const aBalance = parseFloat(a.balance) / Math.pow(10, a.token.decimals);
                    const bBalance = parseFloat(b.balance) / Math.pow(10, b.token.decimals);
                    return aBalance > bBalance ? order : -order;
                }

                if (key === 'usd') {
                    const aPrice = tokenPrices[a.token.symbol];
                    const bPrice = tokenPrices[b.token.symbol];
                    const aBalance = aPrice ? (parseFloat(a.balance) / Math.pow(10, a.token.decimals)) * aPrice : 0;
                    const bBalance = bPrice ? (parseFloat(b.balance) / Math.pow(10, b.token.decimals)) * bPrice : 0;
                    return aBalance > bBalance ? order : -order;
                }

                return a.token.symbol.localeCompare(b.token.symbol) * order;
            });
    }, [balances, sortConfig, tokenPrices]);

    // Update sorting configuration
    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            let direction = 'ascending';
            if (prevConfig.key === key && prevConfig.direction === 'ascending') {
                direction = 'descending';
            }
            return { key, direction };
        });
    };

    // Format large numbers with abbreviations
    const formatBalance = (balance) => {
        if (balance >= 1e9) return (balance / 1e9).toFixed(2) + 'B';
        if (balance >= 1e6) return (balance / 1e6).toFixed(2) + 'M';
        if (balance >= 1e3) return (balance / 1e3).toFixed(2) + 'K';
        return balance.toFixed(2);
    };

    // Function to format wallet address
    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}..${address.slice(-4)}`;
    };

    // Function to truncate token symbols that are longer than 8 characters
    const truncateTokenSymbol = (symbol) => {
        return symbol.length > 8 ? `${symbol.slice(0, 8)}...` : symbol;
    };

    // Function to format numbers as USD
    const formatUSD = (amount) => {
        if (isNaN(amount)) return 'N/A';
        return `$${amount.toFixed(2)}`;
    };

    // Calculate total USD value
    useEffect(() => {
        if (balances.length === 0 || Object.keys(tokenPrices).length === 0) {
            setTotalUSD(0);
            return;
        }

        const calculatedTotalUSD = balances.reduce((total, item) => {
            if (item.token && item.token.symbol && tokenPrices[item.token.symbol] && item.token.symbol !== "SWAPA") {
                const normalizedBalance = parseFloat(item.balance) / Math.pow(10, item.token.decimals);
                return total + (normalizedBalance * tokenPrices[item.token.symbol]);
            }
            return total;
        }, 0);

        setTotalUSD(calculatedTotalUSD);
    }, [balances, tokenPrices]);

    return (
        <div className="wallet-component">
            {/* Header: ANS Profile and Total USD */}
            <div className="wallet-header">
                {ansName ? (
                    <>
                        {ansUri && (
                            <img 
                                src={ansUri} 
                                alt={ansName} 
                                className="ans-avatar"
                            />
                        )}
                        <div className="ans-info">
                            <h2 className="ans-name">
                                <a 
                                    href={`https://explorer.alephium.org/addresses/${walletAddress}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="ans-link"
                                >
                                    {ansName}
                                </a>
                            </h2>
                            <p className="total-usd">Total USD: {formatUSD(totalUSD)}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <img 
                            src={dohLogo} 
                            alt="DOH Logo" 
                            className="doh-logo"
                        />
                        <div className="wallet-info">
                            <span className="wallet-address">
                                <a 
                                    href={`https://explorer.alephium.org/addresses/${walletAddress}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="wallet-link"
                                >
                                    {getWalletName(walletAddress)}
                                </a>
                            </span>
                            <p className="total-usd">Total USD: {formatUSD(totalUSD)}</p>
                        </div>
                    </>
                )}
                <img 
                    src={alphProLogo} 
                    alt="Alph Pro" 
                    className="alphpro-logo"
                />
            </div>

            {/* Tabs for Balances and NFTs */}
            <div className="wallet-tabs">
                <button 
                    onClick={() => setActiveTab('balances')} 
                    className={`wallet-tab ${activeTab === 'balances' ? 'active' : ''}`}
                >
                    Balances
                </button>
                <button 
                    onClick={() => setActiveTab('nfts')} 
                    className={`wallet-tab ${activeTab === 'nfts' ? 'active' : ''}`}
                >
                    NFTs
                </button>
            </div>

            {/* Loading and Error Messages */}
            {loadingBalances && <p className="loading-message">Loading...</p>}
            {activeTab === 'balances' && errorBalances && <p className="wallet-error-message">{errorBalances}</p>}
            {activeTab === 'nfts' && errorNfts && <p className="wallet-error-message">{errorNfts}</p>}

            {/* Render content based on the active tab */}
            {activeTab === 'balances' && sortedBalances.length > 0 && (
                <div className="balances-table-container">
                    <table className="shared-table balances-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('token')} style={{ cursor: 'pointer' }}>
                                    Token <span>{sortConfig.key === 'token' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</span>
                                </th>
                                <th onClick={() => handleSort('balance')} style={{ cursor: 'pointer' }}>
                                    Balance <span>{sortConfig.key === 'balance' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</span>
                                </th>
                                <th onClick={() => handleSort('usd')} style={{ cursor: 'pointer' }}>
                                    USD <span>{sortConfig.key === 'usd' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedBalances.map((item, index) => {
                                const normalizedBalance = parseFloat(item.balance) / Math.pow(10, item.token.decimals);
                                const price = tokenPrices[item.token.symbol];
                                const isSwapa = item.token.symbol === "SWAPA";
                                const usdValue = isSwapa ? 'N/A' : (price ? normalizedBalance * price : 'N/A');

                                const usdValueFormatted = formatUSD(usdValue);

                                return (
                                    <tr key={index}>
                                        <td className="token-cell">
                                            <img 
                                                src={item.token.logo} 
                                                alt={item.token.symbol} 
                                                className="token-logo" 
                                            />
                                            <span className="token-symbol">
                                                {truncateTokenSymbol(item.token.symbol)}
                                            </span>
                                        </td>
                                        <td>{formatBalance(normalizedBalance)}</td>
                                        <td>{usdValueFormatted}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {activeTab === 'nfts' && (
                <div className="nfts-container">
                    {nfts.length > 0 ? (
                        <div className="nfts-grid">
                            {nfts.map((nft, index) => (
                                <div key={index} className="nft-card">
                                    <img 
                                        src={nft.image || alphProLogo} 
                                        alt={nft.name || 'NFT'}
                                        className="nft-image"
                                    />
                                    <div className="nft-info">
                                        <h3 className="nft-name">{nft.name || 'Unnamed NFT'}</h3>
                                        {nft.description && <p className="nft-description">{nft.description}</p>}
                                        {nft.external_url && (
                                            <a 
                                                href={nft.external_url} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="nft-link"
                                            >
                                                View More
                                            </a>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        !loadingBalances && <p className="no-nfts-message">No NFTs found in this wallet.</p>
                    )}
                </div>
            )}
        </div>
    );
};

// PropTypes for validation
Wallet.propTypes = {
    walletAddress: PropTypes.string.isRequired,
};

export default Wallet;
