import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCoins, FaServer, FaExchangeAlt } from 'react-icons/fa';  // Added FaExchangeAlt
import { Box, Typography } from '@mui/material';
import { useWallet } from '@alephium/web3-react';
import { useWalletModal } from './walletmodalcontext';
import useWalletConnection from './usewalletconnection';
import dohLogo from './assets/logos/DOH_Official.png';
import dohBetaLogo from './assets/logos/dohbeta.png'; 
import dohnutlogo from './assets/logos/dohnutlogo.png';
import exIcon from './assets/icons/EX.png'; // Import ex.png
import { ANS } from "@alph-name-service/ans-sdk";
import './navbar.css';

const Navbar = ({ onSearch }) => {
  // Wallet Modal and Connection Hooks
  const { handleOpenModal } = useWalletModal(); // To open wallet modal
  const walletContext = useWallet(); // Wallet context to check wallet status
  const { connectionStatus, account } = walletContext || {}; // Get connection status and account info
  const connected = connectionStatus === 'connected'; // If wallet is connected
  const walletAddress = account?.address || ''; // Wallet address

  // Disconnect logic hooks
  const { handleDisconnect } = useWalletConnection(); // Disconnect wallet hook
  const [isDisconnecting, setIsDisconnecting] = useState(false); // State for disconnecting status
  const [ansName, setAnsName] = useState(''); // State for the ANS profile name
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  // Fetch the ANS profile if the wallet is connected
  useEffect(() => {
    if (connected && walletAddress) {
      fetchAnsProfile(walletAddress);
    }
  }, [connected, walletAddress]);

  const fetchAnsProfile = async (address) => {
    try {
      const ans = new ANS('mainnet');
      const profile = await ans.getProfile(address); // Fetch profile using the Alephium Name Service (ANS)
      setAnsName(profile?.name || ''); // Set profile name, or fallback to empty string
    } catch (error) {
      console.error('Error fetching ANS profile:', error);
    }
  };

  // Helper to truncate wallet address for display
  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  // Handle wallet disconnection
  const onDisconnect = async () => {
    setIsDisconnecting(true); // Set disconnecting state
    try {
      await handleDisconnect(); // Call disconnect function
      walletContext.connectionStatus = 'disconnected'; // Update connection status
      setIsDisconnecting(false); // Reset disconnecting state
    } catch (error) {
      console.error('Failed during disconnection:', error);
      setIsDisconnecting(false); // Reset disconnecting state on error
    }
  };

  // Handle search input change
  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase(); // Convert search query to lowercase
    setSearchQuery(query); // Update search query state
    onSearch(query); // Pass search query to parent component
  };

  // Define the navigation links
  const navLinks = [
    { to: '/home', icon: <FaHome /> },
    { to: '/nodes', icon: <FaServer /> },
    { to: '/tokens', icon: <FaCoins /> },
    { to: '/pools', icon: <img src={exIcon} alt="Token List Icon" className="nav-custom-icon" /> }, // Use ex.png
    { to: '/nftowners', icon: <img src={dohnutlogo} alt="Token List Icon" className="nav-custom-icon" /> },

  ];

  return (
    <nav className="navbar">
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" className="navbar-container">
        {/* Logo Section */}
        <Box display="flex" alignItems="center" justifyContent="center">
          <Link to="/home" className="logo-link">
            <img src={dohLogo} alt="DOH Logo" className="logo" />
          </Link>
          <Link to="/home" className="logo-link">
            <img src={dohBetaLogo} alt="DOH Beta Logo" className="logo-beta" />
          </Link>
        </Box>

        {/* Search Bar */}
        <Box className="search-bar">
          <input
            type="text"
            placeholder="Search tokens..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="search-input"
          />
        </Box>

        {/* Navigation Links for Desktop */}
        <Box className="nav-links-desktop">
          {navLinks.map((link) => (
            <Link to={link.to} key={link.to} className="nav-link">
              <div className="nav-icon">
                {link.icon}
              </div>
            </Link>
          ))}
        </Box>

        {/* Wallet Connect */}
        <Box className="wallet-connect">
          {!connected ? (
            <button onClick={handleOpenModal} className="connect-button">
              Connect Wallet
            </button>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" className="ans-name">
                {ansName || truncateAddress(walletAddress)} {/* Display wallet address or ANS name */}
              </Typography>
              <button
                onClick={onDisconnect}
                className="disconnect-button"
                disabled={isDisconnecting} 
              >
                {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
              </button>
            </Box>
          )}
        </Box>
      </Box>
    </nav>
  );
};

export default Navbar;
