// src/components/TradingViewChart.jsx
import React, { useEffect, useRef } from 'react';

const TradingViewChart = ({ symbol = 'MEXC:ALPHUSDT', width = '100%', height = 500 }) => {
  const chartContainerRef = useRef(null);
  const scriptLoadedRef = useRef(false); // Track if the script is loaded
  const chartRef = useRef(null); // Store the chart reference

  useEffect(() => {
    const initializeWidget = () => {
      if (chartContainerRef.current && window.TradingView) {
        chartRef.current = new window.TradingView.widget({
          container_id: chartContainerRef.current.id,
          width,
          height,
          symbol, // Default symbol
          interval: 'D', // Daily interval
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1', // Candlestick style
          toolbar_bg: '#f1f3f6',
          withdateranges: true,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          details: true,
          hotlist: true,
          calendar: true,
          studies: ['MACD@tv-basicstudies'], // Add studies here if needed
          locale: 'en',
          // Other configuration options as needed
        });
      }
    };

    const scriptId = 'tradingview-widget-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;

      script.onload = () => {
        scriptLoadedRef.current = true;
        initializeWidget();
      };

      document.body.appendChild(script);
    } else if (window.TradingView) {
      initializeWidget();
    }

    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.innerHTML = ''; // Clear the container on unmount
      }
    };
  }, [symbol, width, height]); // Reinitialize widget if props change

  return (
    <div
      id="tradingview-chart"
      ref={chartContainerRef}
      style={{
        width,
        height,
      }}
    />
  );
};

export default TradingViewChart;
