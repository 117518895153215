import React, { useEffect, useState } from 'react';
import axios from 'axios';

function TokenListComponent() {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const apiUrl = 'https://dohcloud.azurewebsites.net/api_token_transactions';

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log('Backend Response:', response.data);

        const sortedPools = response.data.map((pool) => ({
          ...pool,
          transactions: pool.transactions
            ? pool.transactions.map((tx) => ({
                ...tx,
                source: 'elexium', // Assign a default source
                trade_timestamp: Number(tx.trade_timestamp) * 1000, // Ensure timestamp is in ms
              })).sort(
                (a, b) => Number(b.trade_timestamp) - Number(a.trade_timestamp)
              )
            : [],
        }));

        console.log('Sorted Pools:', sortedPools);
        setPools(sortedPools);
      } catch (err) {
        setError('Failed to fetch token data.');
        console.error('Fetch Pools Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPools();
  }, [apiUrl]);

  const formatAddress = (address) => {
    if (!address) return 'N/A';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const formatLink = (url, value) => (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="formatted-link"
    >
      {value}
    </a>
  );

  const toggleRowExpansion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const determineBaseTarget = (pool) => {
    const isBaseToken0 =
      ['ALPH', 'EX'].includes(pool.token0?.symbol) ||
      !['ALPH', 'EX'].includes(pool.token1?.symbol);

    return isBaseToken0
      ? { base: pool.token0, target: pool.token1 }
      : { base: pool.token1, target: pool.token0 };
  };

  const calculatePrice = (tx, pool) => {
    const { base, target } = determineBaseTarget(pool);
    const baseVolume =
      base.symbol === pool.token0?.symbol ? tx.base_volume : tx.target_volume;
    const targetVolume =
      target.symbol === pool.token0?.symbol ? tx.base_volume : tx.target_volume;

    if (baseVolume && targetVolume) {
      return (baseVolume / targetVolume).toFixed(4); // base divided by target
    }
    return 'N/A';
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="token-list-container">
      <h1>Token Pools List</h1>
      {pools.length === 0 ? (
        <p>No data available.</p>
      ) : (
        <table className="pools-table">
          <thead>
            <tr>
              <th>Base Token</th>
              <th>Target Token</th>
              <th>DEX</th>
              <th>AYIN Pool Address</th>
              <th>Pool Type</th>
              <th>Transaction History</th>
            </tr>
          </thead>
          <tbody>
            {pools.map((pool, index) => {
              const { base, target } = determineBaseTarget(pool);
              return (
                <React.Fragment key={index}>
                  <tr className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                    <td>{base.symbol || 'N/A'}</td>
                    <td>{target.symbol || 'N/A'}</td>
                    <td>{pool.dex}</td>
                    <td>
                      {pool.ayinPoolAddress ? (
                        formatLink(
                          `https://explorer.alephium.org/address/${pool.ayinPoolAddress}`,
                          formatAddress(pool.ayinPoolAddress)
                        )
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {pool.ayinPoolAddress ? (
                        <span className="pool-type ayin">AYIN</span>
                      ) : (
                        <span className="pool-type elexium">Elexium</span>
                      )}
                    </td>
                    <td>
                      {pool.transactions && pool.transactions.length > 0 ? (
                        <button
                          className="expand-button"
                          onClick={() => toggleRowExpansion(index)}
                        >
                          {expandedRows[index] ? 'Hide Transactions' : 'Show Transactions'}
                        </button>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                  {expandedRows[index] && (
                    <tr>
                      <td colSpan="6">
                        <h3>Transaction History ({pool.dex})</h3>
                        {pool.transactions && pool.transactions.length > 0 ? (
                          <table className="transactions-table">
                            <thead>
                              <tr>
                                <th>Transaction Type</th>
                                <th>Price</th>
                                <th>{`Base Token (${base.symbol}) Amount`}</th>
                                <th>{`Target Token (${target.symbol}) Amount`}</th>
                                <th>Transaction Time</th>
                                <th>Transaction ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pool.transactions.map((tx, txIndex) => (
                                <tr key={txIndex}>
                                  <td>{tx.type}</td>
                                  <td>{calculatePrice(tx, pool)}</td>
                                  <td>{tx.base_volume?.toFixed(4) || 'N/A'}</td>
                                  <td>{tx.target_volume?.toFixed(4) || 'N/A'}</td>
                                  <td>
                                    {tx.trade_timestamp
                                      ? new Date(Number(tx.trade_timestamp)).toLocaleString()
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {tx.tx_id ? (
                                      formatLink(
                                        `https://explorer.alephium.org/transactions/${tx.tx_id}`,
                                        formatAddress(tx.tx_id)
                                      )
                                    ) : (
                                      'N/A'
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No transactions available.</p>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TokenListComponent;