// src/utils/TokenTransactionAnalyzer.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  OpenInNew as OpenInNewIcon,
  AccountBalanceWallet as WalletIcon,
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import './transactions.css';

const EXPLORER_BASE_URL = 'https://explorer.alephium.org';

/* 
 * Constants for Token IDs
 * Replace 'alph-token-id' and 'doh-token-id' with actual token IDs.
 */
const ALPH_TOKEN_ID = 'alph-token-id'; // Replace with the actual ALPH token ID
const DOH_TOKEN_ID = 'doh-token-id';   // Replace with the actual DOH token ID

const TokenTransactionAnalyzer = ({ tokenId }) => {
  const [tokenList, setTokenList] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');
  const [data, setData] = useState(null);
  const [poolMap, setPoolMap] = useState({}); // Map pool address to pool data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row

  // Replace with your Azure Function URL
  const BACKEND_API_URL = 'https://dohcloud.azurewebsites.net/api_doh_transactions';

  /**
   * Formats a blockchain address by truncating it and wrapping it in a clickable link.
   * @param {string} address - The full blockchain address.
   * @returns {JSX.Element} - A clickable link with the formatted address.
   */
  const formatAddress = (address) => (
    <a
      href={`${EXPLORER_BASE_URL}/addresses/${address}`}
      target="_blank"
      rel="noopener noreferrer"
      className="address-link"
      aria-label={`View address ${address} on Alephium Explorer`}
      id={`address-${address}`} // Adding unique ID for the address link
    >
      {address.slice(0, 6)}...{address.slice(-4)}
    </a>
  );

  /**
   * Fetches the list of tokens from the Alephium token list repository.
   */
  useEffect(() => {
    const fetchTokenList = async () => {
      try {
        const response = await axios.get(
          'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json'
        );
        const tokens = response.data.tokens || [];
        setTokenList(tokens);
      } catch (err) {
        setError('Failed to fetch token list.');
        console.error(err);
      }
    };

    const fetchPoolData = async () => {
      try {
        const response = await axios.get('https://dohcloud.azurewebsites.net/api_doh_pools');
        const pools = response.data.pools || []; // Adjust based on actual API response structure
        // Create a map for quick lookup by pool address
        const poolAddressMap = {};
        pools.forEach((pool) => {
          if (pool.address) {
            poolAddressMap[pool.address] = pool;
          }
        });
        setPoolMap(poolAddressMap);
      } catch (err) {
        setError('Failed to fetch pool data.');
        console.error(err);
      }
    };

    fetchTokenList();
    fetchPoolData();
  }, []);

  /**
   * Sets the selected token based on the `tokenId` prop.
   */
  useEffect(() => {
    if (tokenId) {
      setSelectedToken(tokenId);
    }
  }, [tokenId]);

  /**
   * Fetches transaction data whenever the selected token changes.
   */
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedToken) return;

      setLoading(true);
      setError(null);
      setData(null);

      try {
        const response = await axios.get(BACKEND_API_URL, {
          params: { tokenId: selectedToken },
          // If your Azure Function requires additional parameters, include them here
        });

        setData(response.data);
      } catch (err) {
        setError('Failed to fetch analyzed data.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedToken]);

  /**
   * Handles token selection from the dropdown.
   * @param {object} event - The event object from the select input.
   */
  const handleTokenSelection = async (event) => {
    const newTokenId = event.target.value;
    setSelectedToken(newTokenId);
    setData(null);
    setError(null);

    if (!newTokenId) return;

    setLoading(true);
    try {
      const response = await axios.get(BACKEND_API_URL, {
        params: { tokenId: newTokenId },
      });

      setData(response.data);
    } catch (err) {
      setError('Failed to fetch analyzed data.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Retrieves the symbol for a given token ID.
   * @param {string} tokenId - The ID of the token.
   * @returns {string} - The symbol of the token or a formatted address if not found.
   */
  const getTokenSymbol = (tokenId) => {
    if (!tokenId) return 'N/A';
    // Check if the token is ALPH
    if (tokenId === ALPH_TOKEN_ID) {
      return 'ALPH'; // Always display as ALPH
    }

    // Lookup in the token list
    const token = tokenList.find((t) => t.id === tokenId);
    return token ? token.symbol : formatAddress(tokenId);
  };

  /**
   * Retrieves the logo URI for a given pool address.
   * @param {string} poolAddress - The address of the pool.
   * @returns {string|null} - The logo URI or null if not found.
   */
  const getPoolLogoURI = (poolAddress) => {
    if (!poolAddress) return null;
    const pool = poolMap[poolAddress];
    return pool && pool.logoURI ? pool.logoURI : null;
  };

  /**
   * Toggles the expansion of a transaction row.
   * @param {string} hash - The hash of the transaction.
   */
  const toggleRow = (hash) => {
    setExpandedRow(expandedRow === hash ? null : hash); // Toggle the expanded row
  };

  /**
   * Determines the CSS class for a pool based on its name.
   * @param {string} poolName - The name of the pool.
   * @returns {string} - The corresponding CSS class.
   */
  const getPoolClass = (poolName) => {
    if (!poolName) return '';
    if (poolName.toLowerCase().includes('elexium')) {
      return 'elexium-pool';
    } else if (poolName.toLowerCase().includes('ayin')) {
      return 'ayin-pool';
    }
    return '';
  };

  /**
   * Renders a list of tokens with appropriate styling.
   * Highlights the selected token in green (Got) or red (For).
   * @param {object} tokens - An object containing token IDs and their amounts.
   * @param {boolean} isSent - Indicates if the tokens were sent or received.
   * @returns {JSX.Element} - A styled list of tokens.
   */
  const renderExpandedTokens = (tokens, isSent) => {
    if (!tokens || Object.keys(tokens).length === 0) {
      return <Typography variant="body2" className="no-data">None</Typography>;
    }

    return (
      <ul className="token-list">
        {Object.entries(tokens).map(([tokenId, amount]) => (
          <li
            key={tokenId}
            className={isSent ? 'token-sent' : 'token-received'}
          >
            <strong>{getTokenSymbol(tokenId)}:</strong> {amount.toFixed(2)}
          </li>
        ))}
      </ul>
    );
  };

  /**
   * Renders badges for "Got" and "For" columns.
   * Highlights the selected token with additional styles.
   * @param {object} tokens - An object containing token IDs and their amounts.
   * @param {string} type - The type of badges to render ('got' or 'for').
   * @returns {JSX.Element} - A collection of styled badges.
   */
  const renderBadges = (tokens, type) => {
    if (!tokens || Object.keys(tokens).length === 0) {
      return <Typography variant="body2" className="no-data">N/A</Typography>;
    }

    return Object.entries(tokens).map(([tokenId, amount]) => {
      let badgeClass = type === 'got' ? 'got-badge' : 'for-badge';

      // Highlight the selected token
      if (tokenId === selectedToken) {
        badgeClass += type === 'got' ? ' got-selected-badge' : ' for-selected-badge';
      }

      return (
        <span key={tokenId} className={`badge ${badgeClass}`}>
          {getTokenSymbol(tokenId)}: {amount.toFixed(2)}
        </span>
      );
    });
  };

  /**
   * Formats a timestamp into a relative time string (e.g., "5 minutes ago").
   * @param {string} timestamp - The timestamp to format.
   * @returns {string} - The formatted relative time.
   */
  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    return `${formatDistanceToNow(date, { addSuffix: true })}`;
  };

  /**
   * Formats a wallet address by truncating it for better readability.
   * @param {string} address - The full wallet address.
   * @returns {string} - The truncated wallet address.
   */
  const formatWalletAddress = (address) => {
    if (!address) return 'N/A';
    const first = address.slice(0, 4);
    const last = address.slice(-4);
    return `${first}...${last}`;
  };

  /**
   * Calculates the ratio for a transaction based on the selected token's role.
   * - If the selected token is sent, ratio = Other Token Received / Selected Token Sent
   * - If the selected token is received, ratio = Other Token Sent / Selected Token Received
   * @param {object} tx - The transaction object.
   * @returns {string} - The calculated ratio or 'N/A' if not applicable.
   */
  const calculateRatio = (tx) => {
    if (!tx.pool || !tx.pool.address || !selectedToken) return 'N/A';

    // No normalization; use original cases
    const poolAddress = tx.pool.address;

    // Tokens Sent by Pool Address
    const tokensSent = tx.sentTokens && tx.sentTokens[poolAddress];
    // Tokens Received by Pool Address
    const tokensReceived = tx.receivedTokens && tx.receivedTokens[poolAddress];

    // Determine if the selected token is in "sent" or "received"
    const selectedTokenSent = tokensSent && tokensSent[selectedToken];
    const selectedTokenReceived = tokensReceived && tokensReceived[selectedToken];

    let ratio = null;

    if (selectedTokenSent) {
      // Selected token is in "sent" (GOT)
      // Find the other token received
      const otherTokenReceivedEntries = Object.entries(tokensReceived || {}).filter(
        ([tokenId]) => tokenId !== selectedToken
      );

      // Sum all other tokens received
      const totalOtherReceived = otherTokenReceivedEntries.reduce((acc, [, amt]) => acc + amt, 0);

      if (totalOtherReceived > 0) {
        // Reverse numerator and denominator
        ratio = totalOtherReceived / selectedTokenSent;
      }
    } else if (selectedTokenReceived) {
      // Selected token is in "received" (FOR)
      // Find the other token sent
      const otherTokenSentEntries = Object.entries(tokensSent || {}).filter(
        ([tokenId]) => tokenId !== selectedToken
      );

      // Sum all other tokens sent
      const totalOtherSent = otherTokenSentEntries.reduce((acc, [, amt]) => acc + amt, 0);

      if (totalOtherSent > 0) {
        // Reverse numerator and denominator
        ratio = totalOtherSent / selectedTokenReceived;
      }
    }

    // Return ratio or N/A if no valid calculation
    return ratio !== null ? ratio.toFixed(4) : 'N/A';
  };

  return (
    <Box className="transaction-analyzer-container">
      {/* Token Selection Dropdown */}
      <Box className="token-selection" mb={2}>
        <FormControl fullWidth>
          <InputLabel id="token-select-label">Select a Token</InputLabel>
          <Select
            labelId="token-select-label"
            id="tokenSelect"
            value={selectedToken}
            label="Select a Token"
            onChange={handleTokenSelection}
          >
            <MenuItem value="">
              <em>-- Select a Token --</em>
            </MenuItem>
            {/* Sort tokenList alphabetically by name */}
            {[...tokenList]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((token) => (
                <MenuItem key={token.id} value={token.id}>
                  {token.name} ({token.symbol})
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      {/* Loading and Error States */}
      {loading && (
        <Box className="loading" display="flex" alignItems="center" justifyContent="center" my={2}>
          <CircularProgress />
          <Typography variant="body1" className="loading-text" ml={2}>
            Loading...
          </Typography>
        </Box>
      )}
      {error && (
        <Alert severity="error" className="error-alert" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* ALPH Price Display */}
      {data && data.alphPrice && (
        <Box className="alph-price" mb={2}>
          <Typography variant="h6" className="alph-price-text">
            <strong>ALPH Price:</strong> $
            {data.alphPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      )}

      {/* Transaction Table Display */}
      {data && data.transactions && data.transactions.length > 0 && (
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="transaction table">
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="header-cell" />
                <TableCell className="header-cell">Time</TableCell>
                <TableCell className="header-cell">Hash</TableCell>
                <TableCell className="header-cell">Wallet</TableCell>
                <TableCell className="header-cell">Pool</TableCell>
                <TableCell className="header-cell">Got</TableCell>
                <TableCell className="header-cell">For</TableCell>
                <TableCell className="header-cell">Ratio</TableCell> {/* Ratio Column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.transactions.map((tx) => {
                const isExpanded = expandedRow === tx.hash;
                const poolClass = tx.pool ? getPoolClass(tx.pool.name) : '';

                // Get pool logo using pool address
                const poolLogoURI = tx.pool ? getPoolLogoURI(tx.pool.address) : null;

                // Calculate ratio based on selected token's role
                const ratio = calculateRatio(tx);

                return (
                  <React.Fragment key={tx.hash}>
                    <TableRow
                      className={`table-row ${isExpanded ? 'expanded-row-class' : ''}`}
                      onClick={() => toggleRow(tx.hash)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell className="expand-cell">
                        <IconButton
                          aria-label={isExpanded ? 'Collapse row' : 'Expand row'}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the row click
                            toggleRow(tx.hash);
                          }}
                        >
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </TableCell>

                      <TableCell className="relative-timestamp">
                        {tx.timestamp ? formatRelativeTime(tx.timestamp) : 'N/A'}
                      </TableCell>

                      <TableCell>
                        <IconButton
                          component="a"
                          href={`${EXPLORER_BASE_URL}/transactions/${tx.hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="small"
                          aria-label="View Transaction on Explorer"
                        >
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          component="a"
                          href={`${EXPLORER_BASE_URL}/addresses/${tx.initiatingAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="small"
                          aria-label="View Wallet on Explorer"
                        >
                          <WalletIcon />
                          <Typography variant="body2" className="address-text" ml={1}>
                            {formatWalletAddress(tx.initiatingAddress)}
                          </Typography>
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        {tx.pool ? (
                          <span className={`pool-name ${poolClass}`}>
                            {/* Display Pool Logo */}
                            {poolLogoURI && (
                              <img
                                src={poolLogoURI}
                                alt={getTokenSymbol(tx.pool.tokenId) || 'Pool Logo'}
                                className="pool-logo"
                              />
                            )}
                            {/* Display Pool Name */}
                            <Typography variant="body2" component="span" mr={1}>
                              {tx.pool.name}
                            </Typography>
                            ({formatAddress(tx.pool.address)})
                          </span>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>

                      <TableCell className="got-column">
                        {tx.receivedTokens && tx.receivedTokens[tx.initiatingAddress] && Object.keys(tx.receivedTokens[tx.initiatingAddress]).length > 0 ? (
                          renderBadges(tx.receivedTokens[tx.initiatingAddress], 'got')
                        ) : (
                          <Typography className="no-data">N/A</Typography>
                        )}
                      </TableCell>

                      <TableCell className="for-column">
                        {tx.receivedTokens && tx.receivedTokens[tx.pool.address] && Object.keys(tx.receivedTokens[tx.pool.address]).length > 0 ? (
                          renderBadges(tx.receivedTokens[tx.pool.address], 'for')
                        ) : (
                          <Typography className="no-data">N/A</Typography>
                        )}
                      </TableCell>

                      <TableCell className="ratio-column">
                        {ratio !== 'N/A' ? (
                          <Typography
                            variant="body2"
                            className={`ratio-value ${
                              parseFloat(ratio) > 100 ? 'ratio-high' : parseFloat(ratio) < 1 ? 'ratio-low' : ''
                            }`}
                          >
                            {ratio}
                          </Typography>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>

                    {/* Expanded Row */}
                    <TableRow>
                      <TableCell className="expanded-row-cell" colSpan={8}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box className="transaction-details" p={2}>
                            <Typography variant="h6" gutterBottom>
                              Transaction Details
                            </Typography>
                            <Grid container spacing={2}>
                              {/* General Information */}
                              <Grid item xs={12} md={6}>
                                <Paper elevation={1} className="detail-paper">
                                  <Typography variant="subtitle1" className="detail-title">
                                    General Information
                                  </Typography>
                                  <Divider />
                                  <Box mt={1}>
                                    <Typography variant="body2">
                                      <strong>Block Hash:</strong> {formatAddress(tx.blockHash)}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Timestamp:</strong> {tx.timestamp ? new Date(tx.timestamp).toLocaleString() : 'N/A'}
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Grid>

                              {/* Pool Details */}
                              <Grid item xs={12} md={6}>
                                <Paper elevation={1} className="detail-paper">
                                  <Typography variant="subtitle1" className="detail-title">
                                    Pool Details
                                  </Typography>
                                  <Divider />
                                  <Box mt={1}>
                                    {tx.pool ? (
                                      <Box display="flex" alignItems="center">
                                        {/* Display Pool Logo */}
                                        {poolLogoURI && (
                                          <img
                                            src={poolLogoURI}
                                            alt={getTokenSymbol(tx.pool.tokenId) || 'Pool Logo'}
                                            className="pool-logo"
                                          />
                                        )}
                                        {/* Display Pool Name and Address */}
                                        <Box>
                                          <Typography variant="body2">
                                            <strong>Name:</strong> {tx.pool.name}
                                          </Typography>
                                          <Typography variant="body2">
                                            <strong>Address:</strong> {formatAddress(tx.pool.address)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : (
                                      <Typography variant="body2">N/A</Typography>
                                    )}
                                  </Box>
                                </Paper>
                              </Grid>

                              {/* Initiating Address Tokens Sent/Received */}
                              <Grid item xs={12} md={6}>
                                <Paper elevation={1} className="detail-paper">
                                  <Typography variant="subtitle1" className="detail-title">
                                    Initiating Address Tokens
                                  </Typography>
                                  <Divider />
                                  <Box mt={1}>
                                    {/* Tokens Sent */}
                                    <Box mb={2}>
                                      <Typography variant="body2" className="flow-subtitle">
                                        Tokens Sent:
                                      </Typography>
                                      {tx.sentTokens && tx.sentTokens[tx.initiatingAddress] &&
                                      Object.keys(tx.sentTokens[tx.initiatingAddress]).length > 0 ? (
                                        renderExpandedTokens(tx.sentTokens[tx.initiatingAddress], true)
                                      ) : (
                                        <Typography variant="body2" className="no-data">
                                          None
                                        </Typography>
                                      )}
                                    </Box>
                                    {/* Tokens Received */}
                                    <Box>
                                      <Typography variant="body2" className="flow-subtitle">
                                        Tokens Received:
                                      </Typography>
                                      {tx.receivedTokens && tx.receivedTokens[tx.initiatingAddress] &&
                                      Object.keys(tx.receivedTokens[tx.initiatingAddress]).length > 0 ? (
                                        renderExpandedTokens(tx.receivedTokens[tx.initiatingAddress], false)
                                      ) : (
                                        <Typography variant="body2" className="no-data">
                                          None
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </Paper>
                              </Grid>

                              {/* Pool Address Tokens Sent/Received */}
                              <Grid item xs={12} md={6}>
                                <Paper elevation={1} className="detail-paper">
                                  <Typography variant="subtitle1" className="detail-title">
                                    Pool Address Tokens
                                  </Typography>
                                  <Divider />
                                  <Box mt={1}>
                                    {/* Pool Tokens Sent */}
                                    <Box mb={2}>
                                      <Typography variant="body2" className="flow-subtitle">
                                        Tokens Sent:
                                      </Typography>
                                      {tx.sentTokens && tx.sentTokens[tx.pool.address] &&
                                      Object.keys(tx.sentTokens[tx.pool.address]).length > 0 ? (
                                        renderExpandedTokens(tx.sentTokens[tx.pool.address], true)
                                      ) : (
                                        <Typography variant="body2" className="no-data">
                                          None
                                        </Typography>
                                      )}
                                    </Box>
                                    {/* Pool Tokens Received */}
                                    <Box>
                                      <Typography variant="body2" className="flow-subtitle">
                                        Tokens Received:
                                      </Typography>
                                      {tx.receivedTokens && tx.receivedTokens[tx.pool.address] &&
                                      Object.keys(tx.receivedTokens[tx.pool.address]).length > 0 ? (
                                        renderExpandedTokens(tx.receivedTokens[tx.pool.address], false)
                                      ) : (
                                        <Typography variant="body2" className="no-data">
                                          None
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TokenTransactionAnalyzer;
