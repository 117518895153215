// src/components/TopHoldersTable.js

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { FaWallet } from 'react-icons/fa';
import PropTypes from 'prop-types';

Chart.register(ArcElement, Tooltip, Legend);

// Define custom names mapping
const CUSTOM_NAMES = {
  "wwXHuyXVFBv3zxNf7N3g4D1t2HessF1bpNj6ydDQ1r1m": "ALPH/DOH EX LP",
  "27Ub32AhfC9ULKGKGUTdDGU2ehvUN55aLS4oU8nmW3x9M": "ALPH EX LP",
  "25GTexSvCdbTSewcHfKbVmJKRisZ9cEyJQaNwAgEo5RMy": "APAD EX LP",
  "22iqxjtK14yAD12bE4yiGfJL6XEcoKkMmFZ76DyWtg3wM": "AYIN EX LP",
  "285kUM2LojaKLDPmnc8XxT1azu1G9zR6jjmeEkewJaaWK": "USDT/USDC Stable EX LP",
  "zVRuf1edCyysNDy5nSXEdNcrCJCehfMVpD6kcWqDd9fd": "ALPH/USDT EX LP",
  "24LsfSVVpkFHWFDZpRduuro2WDab3XfmjfyYcAg43K2fq": "ALPH/TOP EX LP",
  "wLqG2MwkA6Y3U53v9sn7NgrcuQGvsNeqm6v6v8GxnGSb": "ALPH/ANS EX LP",
  "27oibfZEc2EcdUNxg8L6VshJ3R1WVCRqGAGuFHG6AMhkK": "GRUMPY/ALPH EX LP"
};

// Utility function to format wallet address
const formatWalletAddress = (address) => `${address.slice(0, 4)}...${address.slice(-4)}`;

const TopHoldersTable = React.memo(({ tokenAddress, pairAddress, selectedWalletAddress, onHolderSelect }) => {
  const [holders, setHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [holderNames, setHolderNames] = useState({});
  const [tokenSymbol, setTokenSymbol] = useState(''); // State to store the token symbol
  const tableRef = useRef(null); // Reference to the table for scrolling

  // Fetch holders data
  useEffect(() => {
    const fetchHolders = async () => {
      if (!tokenAddress) {
        setError('Token address is missing.');
        return;
      }

      setLoading(true);
      setError(null);
      setHolders([]);
      setHolderNames({});
      setTokenSymbol('');

      try {
        const response = await axios.get(
          `https://dohcloud.azurewebsites.net/api_GetTopHolders?tokenAddress=${tokenAddress}`
        );

        if (!response.data.holders || response.data.holders.length === 0) {
          setError('No holders data found.');
          setLoading(false);
          return;
        }

        const tokenData = response.data.holders[0];
        const tokenDecimals = tokenData.token.decimals || 18;
        const circulatingSupply = parseFloat(tokenData.circulatingSupply) / Math.pow(10, tokenDecimals);
        const holdersData = tokenData.holders || [];

        // Extract token symbol if available
        const symbol = tokenData.token.symbol || 'TOKEN';
        setTokenSymbol(symbol);

        const sortedHolders = holdersData
          .map(holder => {
            const realBalanceRaw = parseFloat(holder.balance) / Math.pow(10, tokenDecimals);
            const percentageOwnership = ((realBalanceRaw / circulatingSupply) * 100).toFixed(2);

            return {
              ...holder,
              realBalanceRaw,
              realBalance: formatBalance(realBalanceRaw),
              percentage: percentageOwnership,
            };
          })
          .sort((a, b) => b.realBalanceRaw - a.realBalanceRaw); // Ensure numeric sort

        setHolders(sortedHolders);

        // Assign names based on custom names or formatted addresses
        const newHolderNames = {};
        sortedHolders.forEach(holder => {
          if (holder.userAddress === pairAddress) {
            newHolderNames[holder.userAddress] = `${symbol} AYIN LP`;
          } else if (CUSTOM_NAMES[holder.userAddress]) {
            newHolderNames[holder.userAddress] = CUSTOM_NAMES[holder.userAddress];
          } else {
            newHolderNames[holder.userAddress] = formatWalletAddress(holder.userAddress);
          }
        });
        setHolderNames(newHolderNames);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch holders.');
      } finally {
        setLoading(false);
      }
    };

    fetchHolders();
  }, [tokenAddress, pairAddress]);

  // Utility to format balance
  const formatBalance = (balance) => {
    if (balance >= 1e9) return `${(balance / 1e9).toFixed(2)}B`;
    if (balance >= 1e6) return `${(balance / 1e6).toFixed(2)}M`;
    if (balance >= 1e3) return `${(balance / 1e3).toFixed(2)}K`;
    return balance.toFixed(2);
  };

  // Pie Chart Data
  const pieChartData = useMemo(() => ({
    labels: holders.map(holder => CUSTOM_NAMES[holder.userAddress] || formatWalletAddress(holder.userAddress)),
    datasets: [
      {
        label: 'Percentage of Total Supply',
        data: holders.map(holder => holder.percentage),
        backgroundColor: holders.map(holder =>
          holder.userAddress === selectedWalletAddress
            ? 'rgba(255, 165, 0, 1)'  // Orange for selected
            : holder.userAddress === pairAddress
              ? 'rgba(30, 144, 255, 0.8)'  // Dodger Blue for Pair Address
              : 'rgba(51, 51, 51, 0.8)'  // Dark Gray for others
        ),
        borderColor: 'rgba(0, 0, 0, 0.8)',
        hoverBorderColor: 'rgba(255, 215, 0, 1)',
        borderWidth: 1,
      },
    ],
  }), [holders, selectedWalletAddress, pairAddress]);

  // Pie Chart Options
  const pieChartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false, // Allow for custom height
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const holder = holders[tooltipItem.dataIndex];
            return `${tooltipItem.label}: ${tooltipItem.raw}% (${holder.realBalance})`;
          },
        },
      },
      legend: {
        display: false // Hide legend for a cleaner look
      },
    },
    layout: {
      backgroundColor: 'transparent', // Ensure the layout background is transparent
    },
    elements: {
      arc: {
        backgroundColor: 'transparent', // Ensure arcs don't have their own background
      },
    },
    onHover: (event, elements) => {
      if (elements.length > 0) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const holderAddress = holders[index].userAddress;
        onHolderSelect(holderAddress); // Notify parent of selection

        // Scroll to the selected holder in the table
        const table = tableRef.current;
        if (table) {
          const row = table.querySelector(`tr[data-address="${holderAddress}"]`);
          if (row) {
            row.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // Optionally, you can set focus to the row
            row.focus();
          }
        }
      }
    },
  }), [holders, onHolderSelect, pairAddress]);

  // Handle row click
  const handleWalletAddressClick = useCallback((address) => {
    onHolderSelect(address); // Notify parent of selection
  }, [onHolderSelect]);

  // Scroll to the selected wallet when selectedWalletAddress changes
  useEffect(() => {
    if (selectedWalletAddress && tableRef.current) {
      const row = tableRef.current.querySelector(`tr[data-address="${selectedWalletAddress}"]`);
      if (row) {
        row.scrollIntoView({ behavior: 'smooth', block: 'center' });
        row.focus();
      }
    }
  }, [selectedWalletAddress]);

  return (
    <div className="top-holders-container">
      <div className="holders-info-section">
        {/* Left side: Token Holders Table */}
        <div className="top-holders-table-container">
          {loading && <p className="loading-message">Loading top holders...</p>}
          {error && <p className="error-message">{error}</p>}

          {!loading && !error && holders.length > 0 && (
            <table className="holders-table" ref={tableRef}>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>%</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {holders.map((holder) => {
                  const isSelected = selectedWalletAddress === holder.userAddress;
                  return (
                    <tr
                      key={holder.userAddress} // Unique wallet address as key
                      data-address={holder.userAddress} // Add data attribute for easy selection
                      onClick={() => handleWalletAddressClick(holder.userAddress)}
                      className={isSelected ? 'selected' : ''}
                      style={{
                        cursor: 'pointer',
                      }}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleWalletAddressClick(holder.userAddress);
                        }
                      }}
                    >
                      <td>
                        <a
                          href={`https://explorer.alephium.org/addresses/${holder.userAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()} // Prevent link click from affecting the row selection
                          className="wallet-icon-link"
                          title="View on Explorer"
                        >
                          <FaWallet className="wallet-icon-small" />
                        </a>
                        <span style={{ marginLeft: '8px' }}>
                          {CUSTOM_NAMES[holder.userAddress] || formatWalletAddress(holder.userAddress)}
                        </span>
                        {holder.userAddress === pairAddress && ' (LP)'}
                      </td>
                      <td>
                        <div className="percentage-container">
                          {holder.percentage} %
                          <div
                            className="percentage-bar"
                            style={{ width: `${holder.percentage}%` }}
                            title={`${holder.percentage}% held by this address`}
                          ></div>
                        </div>
                      </td>
                      <td>{holder.realBalance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {/* Right side: Pie Chart */}
        <div className="holders-chart-container">
          {!loading && !error && holders.length > 0 && (
            <Pie data={pieChartData} options={pieChartOptions} />
          )}
        </div>
      </div>
    </div>
  );
});

TopHoldersTable.propTypes = {
  tokenAddress: PropTypes.string.isRequired,
  pairAddress: PropTypes.string.isRequired,
  selectedWalletAddress: PropTypes.string, // Selected wallet address to highlight
  onHolderSelect: PropTypes.func.isRequired, // Callback to notify parent
};

export default TopHoldersTable;
