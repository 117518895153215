import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';

// Constants
const ALPH_TOKEN_ID = 'ALPH_TOKEN_ID_ACTUAL'; // Replace with the actual ALPH Token ID
const POOL_INFO_API_URL = 'https://dohcloud.azurewebsites.net/api_test_transactions';
const TOKEN_LIST_URL = 'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json';
const TRANSACTIONS_API_URL = 'https://backend.mainnet.alephium.org/tokens';

// Token mapping with DOH included (You can expand this as needed)
const tokenDetails = {
  "93db3ced5685c6e5438afcc94fc20a1bca8885be75c02f6f164da30f217c2500": { name: "DOH", decimals: 18 },
  "19e2692626fa55ba80dc3568b46c49347fe7fe482a6ec86118995c99365d8400": { name: "OTHER_TOKEN", decimals: 18 }
  // Add more tokens as needed
};

// Helper Functions
const formatAddressOrHash = (value) => {
  return value.length > 10 ? `${value.slice(0, 6)}...${value.slice(-4)}` : value;
};

const convertTimestampToRelativeTime = (ts) => {
  return moment(ts).fromNow();
};

const formatTokenAmount = (amount, decimals = 18) => {
  const formatted = parseFloat(amount) / Math.pow(10, decimals);
  return formatted.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
};

// Updated TokenTransactions Component
const TokenTransactions = () => {
  // State variables
  const [tokenList, setTokenList] = useState([]);
  const [poolInfo, setPoolInfo] = useState([]);
  const [openedTokens, setOpenedTokens] = useState({}); // { tokenId: { transactions: [], isOpen: true/false } }
  const [loadingTokens, setLoadingTokens] = useState(false);
  const [loadingPools, setLoadingPools] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState({}); // { tokenId: true/false }
  const [error, setError] = useState(null);

  const LIMIT = 30;

  useEffect(() => {
    fetchTokenList();
    fetchPoolInfo();
  }, []);

  // Fetch the list of tokens
  const fetchTokenList = async () => {
    setLoadingTokens(true);
    setError(null);
    try {
      const response = await axios.get(TOKEN_LIST_URL);
      const data = response.data;
      if (data && data.tokens) {
        // Manually add ALPH if it's not already in the list
        const alphExists = data.tokens.some(token => token.id === ALPH_TOKEN_ID);
        const updatedTokens = alphExists
          ? data.tokens
          : [
              ...data.tokens,
              {
                id: ALPH_TOKEN_ID,
                name: 'Alephium',
                symbol: 'ALPH',
                decimals: 18, // Adjust if ALPH has different decimals
                // Add any other necessary fields
              },
            ];
        setTokenList(updatedTokens);
        console.log('Fetched token list:', updatedTokens);
      } else {
        setError('Failed to fetch token list.');
        console.error('Token list data structure unexpected:', data);
      }
    } catch (err) {
      console.error('Error fetching token list:', err);
      setError('Failed to fetch token list.');
    } finally {
      setLoadingTokens(false);
    }
  };

  // Fetch pool information
  const fetchPoolInfo = async () => {
    setLoadingPools(true);
    setError(null);
    try {
      const response = await axios.get(POOL_INFO_API_URL);
      const data = response.data;
      if (Array.isArray(data)) {
        setPoolInfo(data);
        console.log('Fetched pool information:', data);
      } else {
        setError('Failed to fetch pool information.');
        console.error('Pool information data structure unexpected:', data);
      }
    } catch (err) {
      console.error('Error fetching pool information:', err);
      setError('Failed to fetch pool information.');
    } finally {
      setLoadingPools(false);
    }
  };

  // Fetch transactions for a specific token
  const fetchTransactionsForToken = async (tokenId) => {
    // If transactions are already loaded, simply toggle the open state
    if (openedTokens[tokenId] && openedTokens[tokenId].transactions.length > 0) {
      setOpenedTokens((prev) => ({
        ...prev,
        [tokenId]: {
          ...prev[tokenId],
          isOpen: !prev[tokenId].isOpen,
        },
      }));
      return;
    }

    // Else, fetch transactions
    setLoadingTransactions((prev) => ({ ...prev, [tokenId]: true }));
    setError(null);
    try {
      console.log(`Fetching transactions for Token ID: ${tokenId}`);
      const response = await axios.get(`${TRANSACTIONS_API_URL}/${tokenId}/transactions`, {
        params: {
          page: 1,
          limit: LIMIT,
        },
      });
      const data = response.data;
      console.log('Fetched transactions data:', data);
      if (Array.isArray(data)) {
        setOpenedTokens((prev) => ({
          ...prev,
          [tokenId]: {
            transactions: data,
            isOpen: true,
          },
        }));
      } else {
        setOpenedTokens((prev) => ({
          ...prev,
          [tokenId]: {
            transactions: [],
            isOpen: true,
          },
        }));
        setError('No transactions found for this token.');
        console.warn('Unexpected transactions data structure:', data);
      }
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError('Failed to fetch transactions.');
    } finally {
      setLoadingTransactions((prev) => ({ ...prev, [tokenId]: false }));
    }
  };

  // Get decimals for a specific token
  const getTokenDecimals = (tokenId) => {
    const token = tokenList.find((t) => t.id === tokenId);
    return token ? token.decimals : 18; // Default to 18 decimals if not found
  };

  // Determine which DEX the transaction is part of based on pool information
  const determineDEX = (tx) => {
    const involvedPools = [];

    // Check against elexiumPool and ayinPool
    poolInfo.forEach((pool) => {
      if (pool.elexiumPool) {
        const ePool = pool.elexiumPool;
        if (
          tx.inputs.some(input => input.address === ePool.poolAddress) ||
          tx.outputs.some(output => output.address === ePool.poolAddress)
        ) {
          involvedPools.push('Elexium DEX');
        }
      }

      if (pool.ayinPool) {
        const aPool = pool.ayinPool;
        if (
          tx.inputs.some(input => input.address === aPool.address) ||
          tx.outputs.some(output => output.address === aPool.address)
        ) {
          involvedPools.push('AYIN DEX');
        }
      }
    });

    // Remove duplicates
    const uniqueDEX = [...new Set(involvedPools)];

    return uniqueDEX.length > 0 ? uniqueDEX.join(', ') : 'Unknown DEX';
  };

  // Verify the ALPH amount in a transaction
  const verifyALPHAmount = (tx) => {
    let alphAmountIn = 0;
    let alphAmountOut = 0;
    const decimals = 18; // Adjust if ALPH has different decimals

    // Process Inputs
    if (tx.inputs.length > 0) {
      tx.inputs.forEach((input) => {
        if (input.alph) { // Adjust based on actual transaction structure
          alphAmountIn += parseFloat(formatTokenAmount(input.alph, decimals));
        }
        if (input.tokens) {
          input.tokens.forEach((token) => {
            if (token.id === ALPH_TOKEN_ID) {
              alphAmountIn += parseFloat(formatTokenAmount(token.amount, decimals));
            }
          });
        }
      });
    }

    // Process Outputs
    if (tx.outputs.length > 0) {
      tx.outputs.forEach((output) => {
        if (output.alph) { // Adjust based on actual transaction structure
          alphAmountOut += parseFloat(formatTokenAmount(output.alph, decimals));
        }
        if (output.tokens) {
          output.tokens.forEach((token) => {
            if (token.id === ALPH_TOKEN_ID) {
              alphAmountOut += parseFloat(formatTokenAmount(token.amount, decimals));
            }
          });
        }
      });
    }

    const netAlphAmount = alphAmountOut - alphAmountIn;

    // Define your own logic for what is considered "correct"
    // For demonstration, let's assume any non-zero net amount is correct
    if (netAlphAmount !== 0) {
      return 'Correct';
    } else {
      return 'Incorrect';
    }
  };

  // Determine the type of transaction (BUY/SELL/OTHER)
  const determineTransactionType = (tx, selectedTokenId) => {
    let tokenAmountIn = 0;
    let tokenAmountOut = 0;
    const decimals = getTokenDecimals(selectedTokenId);

    // Process Inputs
    if (tx.inputs.length > 0) {
      tx.inputs.forEach((input) => {
        if (input.tokens) {
          input.tokens.forEach((token) => {
            if (token.id === selectedTokenId) {
              tokenAmountIn += parseFloat(formatTokenAmount(token.amount, decimals));
            }
          });
        }
      });
    }

    // Process Outputs
    if (tx.outputs.length > 0) {
      tx.outputs.forEach((output) => {
        if (output.tokens) {
          output.tokens.forEach((token) => {
            if (token.id === selectedTokenId) {
              tokenAmountOut += parseFloat(formatTokenAmount(token.amount, decimals));
            }
          });
        }
      });
    }

    const netTokenAmount = tokenAmountOut - tokenAmountIn;

    if (netTokenAmount > 0) {
      return 'BUY';
    } else if (netTokenAmount < 0) {
      return 'SELL';
    } else {
      return 'OTHER';
    }
  };

  // Calculate totals for each token using useMemo
  const totalsByToken = useMemo(() => {
    const totals = {};

    Object.entries(openedTokens).forEach(([tokenId, { transactions }]) => {
      let totalTokenAmount = 0;
      let totalAlphAmount = 0;

      transactions.forEach(tx => {
        // Sum token amounts
        tx.inputs.forEach(input => {
          if (input.tokens) {
            input.tokens.forEach(t => {
              if (t.id === tokenId) {
                totalTokenAmount -= parseFloat(formatTokenAmount(t.amount, getTokenDecimals(tokenId)));
              }
              if (t.id === ALPH_TOKEN_ID) {
                totalAlphAmount -= parseFloat(formatTokenAmount(t.amount, 18));
              }
            });
          }
          if (input.alph) {
            totalAlphAmount -= parseFloat(formatTokenAmount(input.alph, 18));
          }
        });

        tx.outputs.forEach(output => {
          if (output.tokens) {
            output.tokens.forEach(t => {
              if (t.id === tokenId) {
                totalTokenAmount += parseFloat(formatTokenAmount(t.amount, getTokenDecimals(tokenId)));
              }
              if (t.id === ALPH_TOKEN_ID) {
                totalAlphAmount += parseFloat(formatTokenAmount(t.amount, 18));
              }
            });
          }
          if (output.alph) {
            totalAlphAmount += parseFloat(formatTokenAmount(output.alph, 18));
          }
        });
      });

      totals[tokenId] = { totalTokenAmount, totalAlphAmount };
    });

    console.log('Totals by Token:', totals); // Debugging log
    return totals;
  }, [openedTokens, getTokenDecimals]);

  return (
    <div className="token-transactions-container">
      <h2>Token List</h2>
      {(loadingTokens || loadingPools) && <p>Loading tokens and pool information...</p>}
      {error && <p className="error">{error}</p>}
      <ul className="token-list">
        {tokenList.map((token) => (
          <li key={token.id} className="token-list-item">
            <div className="token-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>
                {token.name} ({token.symbol})
              </span>
              <button onClick={() => fetchTransactionsForToken(token.id)}>
                {openedTokens[token.id] && openedTokens[token.id].isOpen ? 'Hide Transactions' : 'Show Transactions'}
              </button>
            </div>
            {openedTokens[token.id] && openedTokens[token.id].isOpen && (
              <div className="transactions-section" style={{ marginTop: '10px' }}>
                <h4>Transactions for {token.symbol}</h4>
                {loadingTransactions[token.id] && <p>Loading transactions...</p>}
                {openedTokens[token.id].transactions.length === 0 && !loadingTransactions[token.id] && (
                  <p>No transactions found.</p>
                )}
                {openedTokens[token.id].transactions.length > 0 && (
                  <table className="transaction-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Time</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Transaction Hash</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Initiating Wallet</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>Token Amount</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>ALPH Amount</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Type</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>DEX</th>
                        <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ALPH Verification</th>
                      </tr>
                    </thead>
                    <tbody>
                      {openedTokens[token.id].transactions.map((tx) => {
                        const initiatingWallet = tx.inputs.length > 0 ? tx.inputs[0].address : 'N/A';

                        // Determine Transaction Type
                        const transactionType = determineTransactionType(tx, token.id);

                        // Verify ALPH Amount
                        const alphVerification = verifyALPHAmount(tx);

                        // Determine DEX
                        const dex = determineDEX(tx);

                        // Calculate ALPH Net Amount
                        let alphNetAmount = 0;
                        if (tx.inputs) {
                          tx.inputs.forEach((input) => {
                            if (input.alph) { // Adjust based on actual transaction structure
                              alphNetAmount -= parseFloat(formatTokenAmount(input.alph, 18));
                            }
                            if (input.tokens) {
                              input.tokens.forEach((token) => {
                                if (token.id === ALPH_TOKEN_ID) {
                                  alphNetAmount -= parseFloat(formatTokenAmount(token.amount, 18));
                                }
                              });
                            }
                          });
                        }
                        if (tx.outputs) {
                          tx.outputs.forEach((output) => {
                            if (output.alph) { // Adjust based on actual transaction structure
                              alphNetAmount += parseFloat(formatTokenAmount(output.alph, 18));
                            }
                            if (output.tokens) {
                              output.tokens.forEach((token) => {
                                if (token.id === ALPH_TOKEN_ID) {
                                  alphNetAmount += parseFloat(formatTokenAmount(token.amount, 18));
                                }
                              });
                            }
                          });
                        }

                        const readableTime = convertTimestampToRelativeTime(tx.timestamp);

                        return (
                          <tr key={tx.tx_id || tx.hash}>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{readableTime}</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                              <a
                                href={`https://explorer.alephium.org/transactions/${tx.hash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {formatAddressOrHash(tx.hash)}
                              </a>
                            </td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                              <a
                                href={`https://explorer.alephium.org/addresses/${initiatingWallet}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {formatAddressOrHash(initiatingWallet)}
                              </a>
                            </td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                              {(() => {
                                let tokenAmountIn = 0;
                                let tokenAmountOut = 0;
                                const decimals = getTokenDecimals(token.id);

                                // Inputs
                                tx.inputs.forEach((input) => {
                                  if (input.tokens) {
                                    input.tokens.forEach((t) => { // Renamed variable to 't'
                                      if (t.id === token.id) { // Correct comparison
                                        tokenAmountIn += parseFloat(formatTokenAmount(t.amount, decimals));
                                      }
                                    });
                                  }
                                });

                                // Outputs
                                tx.outputs.forEach((output) => {
                                  if (output.tokens) {
                                    output.tokens.forEach((t) => { // Renamed variable to 't'
                                      if (t.id === token.id) { // Correct comparison
                                        tokenAmountOut += parseFloat(formatTokenAmount(t.amount, decimals));
                                      }
                                    });
                                  }
                                });

                                const netTokenAmount = tokenAmountOut - tokenAmountIn;
                                return netTokenAmount.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
                              })()}
                            </td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
                              {alphNetAmount.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })} Ⱡ
                            </td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{transactionType}</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{dex}</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{alphVerification}</td>
                          </tr>
                        );
                      })}

                      {/* Totals row */}
                      {openedTokens[token.id].transactions.length > 0 && (
                        <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                          <td colSpan="3" style={{ textAlign: 'right', padding: '8px' }}>Totals:</td>
                          <td style={{ textAlign: 'right', padding: '8px' }}>
                            {totalsByToken[token.id]?.totalTokenAmount.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }) || '0.0000'}
                          </td>
                          <td style={{ textAlign: 'right', padding: '8px' }}>
                            {totalsByToken[token.id]?.totalAlphAmount.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }) || '0.0000'} Ⱡ
                          </td>
                          <td colSpan="3" style={{ padding: '8px' }}></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TokenTransactions;
