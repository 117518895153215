// src/components/TokenListPools.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
  Collapse,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  Box,
  Grid,
  Pagination,
  Skeleton,
  Divider,
  Button,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import {
  Search as SearchIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  SwapHoriz as SwapHorizIcon, // Replaced TransactionIcon with SwapHorizIcon
} from '@mui/icons-material';
import TradingViewChart from './tradingviewchart'; // Ensure correct path
import TokenTransactionAnalyzer from '../utils/transactions'; // Updated import
import './tokenlist_pools.css';

const BACKEND_API_URL = 'https://dohcloud.azurewebsites.net/api_doh_pools'; // Update if needed

const TokenListPools = () => {
  const [tokens, setTokens] = useState([]);
  const [displayedTokens, setDisplayedTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expandedToken, setExpandedToken] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterDEX, setFilterDEX] = useState('all');
  const [sortOption, setSortOption] = useState('highestTVL'); // Default sorting
  const [darkMode, setDarkMode] = useState(true); // Dark mode enabled by default
  const [currentPage, setCurrentPage] = useState(1);
  const tokensPerPage = 10;

  // State to track which sections within the expanded token are open
  const [expandedSections, setExpandedSections] = useState({
    // Example structure:
    // tokenId: { elexiumPools: true, ayinPools: false, transactions: false }
  });

  // Define MUI themes
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#FFD700',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#ffffff',
        secondary: '#bdbdbd',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#FFD700',
      },
      background: {
        default: '#f5f5f5',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
        secondary: '#555555',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  // Persist dark mode preference in localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get(BACKEND_API_URL);

        // Enrich tokens with elexiumPools and ayinPools arrays
        const enrichedTokens = response.data.map((token) => ({
          ...token,
          elexiumPools: token.elexiumPools || [], // Ensure it's an array
          ayinPools: token.ayinPools || [], // Ensure it's an array
        }));

        setTokens(enrichedTokens);
        setDisplayedTokens(enrichedTokens);
      } catch (err) {
        console.error('Error fetching token data:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  // Handle Search, Filter, and Sort functionalities
  useEffect(() => {
    let filtered = tokens.filter((token) => {
      const query = searchQuery.toLowerCase();
      return (
        token.name.toLowerCase().includes(query) ||
        token.symbol.toLowerCase().includes(query)
      );
    });

    // Apply DEX Filter
    if (filterDEX === 'ayin') {
      filtered = filtered.filter((token) => token.ayinPools.length > 0);
    } else if (filterDEX === 'elexium') {
      filtered = filtered.filter((token) => token.elexiumPools.length > 0);
    } else if (filterDEX === 'both') {
      filtered = filtered.filter(
        (token) => token.ayinPools.length > 0 && token.elexiumPools.length > 0
      );
    }

    // Apply Sorting
    if (sortOption === 'highestTVL') {
      filtered.sort((a, b) => {
        const tvlA = Math.max(
          ...a.elexiumPools.map((pool) => parseFloat(pool.tvl) || 0),
          ...a.ayinPools.map((pool) => parseFloat(pool.tvl) || 0)
        );
        const tvlB = Math.max(
          ...b.elexiumPools.map((pool) => parseFloat(pool.tvl) || 0),
          ...b.ayinPools.map((pool) => parseFloat(pool.tvl) || 0)
        );
        return tvlB - tvlA;
      });
    }

    setDisplayedTokens(filtered);
    setCurrentPage(1); // Reset to first page on filter/sort
    setExpandedToken(null); // Collapse any expanded row when filtering/sorting
    setExpandedSections({}); // Reset expanded sections
  }, [searchQuery, filterDEX, sortOption, tokens]);

  // Toggle Dark Mode
  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  // Handle Pagination Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Helper function to format addresses
  const formatAddress = (address) => (
    <a
      href={`https://explorer.alephium.org/addresses/${address}`}
      target="_blank"
      rel="noopener noreferrer"
      className="address-link"
      id={`address-${address}`} // Adding unique ID for the address link
    >
      {address.slice(0, 6)}...{address.slice(-4)}
    </a>
  );

  // Determine which DEX the token is part of
  const determineDEX = (token) => {
    const dexList = [];
    if (token.elexiumPools.length > 0) dexList.push('Elexium DEX');
    if (token.ayinPools.length > 0) dexList.push('AYIN DEX');
    return dexList.length > 0 ? dexList.join(', ') : 'Unknown DEX';
  };

  // Function to calculate aggregated metrics
  const calculateMetrics = (token) => {
    let totalVolume = 0;
    let totalTVL = 0;
    let prices = [];
    let apr = 'N/A';

    token.elexiumPools.forEach((pool) => {
      totalVolume += parseFloat(pool.volume) || 0;
      totalTVL += parseFloat(pool.tvl) || 0;
      if (pool.onchainPrice) {
        prices.push(parseFloat(pool.onchainPrice));
      }
      if (pool.gauge && pool.gauge.apr) {
        apr = `${(parseFloat(pool.gauge.apr) * 100).toFixed(2)}%`; // Convert to percentage
      }
    });

    token.ayinPools.forEach((pool) => {
      totalVolume += parseFloat(pool.volume) || 0;
      totalTVL += parseFloat(pool.tvl) || 0;
      if (pool.onchainPrice) {
        prices.push(parseFloat(pool.onchainPrice));
      }
    });

    const averagePrice =
      prices.length > 0
        ? (prices.reduce((a, b) => a + b, 0) / prices.length).toFixed(2)
        : 'N/A';

    let onchainPrice = 'N/A';

    // Check for 'ALPH', 'EXY', or 'ABX' symbol and use token0's on-chain price
    if (
      token.symbol.toLowerCase() === 'alph' ||
      token.symbol.toLowerCase() === 'exy' ||
      token.symbol.toLowerCase() === 'abx'
    ) {
      // For Alephium (ALPH), EXY, and ABX tokens, use token0's on-chain price
      const firstElexiumPool = token.elexiumPools[0];
      if (firstElexiumPool?.token0?.onchainPrice) {
        onchainPrice = `$${parseFloat(firstElexiumPool.token0.onchainPrice).toFixed(4)}`;
      }
    } else {
      // For other tokens, use token1's on-chain price
      const firstElexiumPool = token.elexiumPools[0];
      if (firstElexiumPool?.token1?.onchainPrice) {
        onchainPrice = `$${parseFloat(firstElexiumPool.token1.onchainPrice).toFixed(4)}`;
      } else if (token.ayinPools[0]?.token1?.onchainPrice) {
        onchainPrice = `$${parseFloat(token.ayinPools[0].token1.onchainPrice).toFixed(4)}`;
      }
    }

    return {
      totalVolume: totalVolume > 0 ? totalVolume.toLocaleString() : 'N/A',
      totalTVL: totalTVL > 0 ? totalTVL.toLocaleString() : 'N/A',
      averagePrice,
      apr,
      onchainPrice,
    };
  };

  // Function to determine TradingView symbol
  const getTradingViewSymbol = (token) => {
    // Example implementation based on token symbol
    // You might need to adjust this logic based on your actual data
    const symbolMap = {
      alph: 'MEXC:ALPHUSDT',
      exy: 'BINANCE:EXYUSDT',
      // Add more mappings as needed
    };

    return symbolMap[token.symbol.toLowerCase()] || 'BINANCE:BTCUSDT'; // Default to BTC if not found
  };

  // Calculate tokens to display based on current page
  const indexOfLastToken = currentPage * tokensPerPage;
  const indexOfFirstToken = indexOfLastToken - tokensPerPage;
  const currentTokens = displayedTokens.slice(indexOfFirstToken, indexOfLastToken);
  const totalPages = Math.ceil(displayedTokens.length / tokensPerPage);

  // Handle main row click to expand/collapse token details
  const handleRowClick = (tokenId) => {
    setExpandedToken((prev) => (prev === tokenId ? null : tokenId));
  };

  // Handle section button click within token details
  const handleSectionToggle = (tokenId, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [tokenId]: {
        ...prev[tokenId],
        [section]: !prev[tokenId]?.[section],
      },
    }));
  };

  // Handle Transactions Button Click
  const handleTransactionsClick = (tokenId) => {
    setExpandedSections((prev) => ({
      ...prev,
      [tokenId]: {
        ...prev[tokenId],
        transactions: !prev[tokenId]?.transactions,
      },
    }));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className={`token-list-container ${darkMode ? 'dark' : 'light'}`}>
        {/* AppBar */}
        <AppBar
          position="static"
          sx={{
            backgroundColor: darkMode ? '#000000' : undefined, // Black in dark mode
            color: darkMode ? '#FFD700' : undefined, // Gold text in dark mode
          }}
          elevation={0} // Flat look
        >
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Alephium Tokens Dashboard
            </Typography>
            <Tooltip title="Toggle Dark Mode">
              <IconButton
                onClick={handleDarkModeToggle}
                color="inherit"
                aria-label="Toggle dark mode"
              >
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        {/* Main Content */}
        <Container style={{ padding: '2rem 0' }}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'stretch', md: 'center' }}
            mb={3}
            gap={2}
          >
            {/* Search Bar */}
            <FormControl variant="outlined" fullWidth>
              <TextField
                variant="outlined"
                placeholder="Search by name or symbol"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      style={{ marginRight: '0.5rem', color: darkMode ? '#FFD700' : '#000' }}
                    />
                  ),
                  style: { color: darkMode ? '#FFD700' : '#000' },
                }}
                inputProps={{
                  'aria-label': 'Search Tokens',
                }}
              />
            </FormControl>

            {/* DEX Filter */}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="dex-filter-label" style={{ color: darkMode ? '#FFD700' : undefined }}>
                Filter by DEX
              </InputLabel>
              <Select
                labelId="dex-filter-label"
                value={filterDEX}
                onChange={(e) => setFilterDEX(e.target.value)}
                label="Filter by DEX"
                style={{ color: darkMode ? '#FFD700' : undefined }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkMode ? '#1e1e1e' : '#fff',
                      color: darkMode ? '#FFD700' : '#000',
                    },
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="ayin">AYIN DEX</MenuItem>
                <MenuItem value="elexium">Elexium DEX</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>

            {/* Sort Option */}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="sort-option-label" style={{ color: darkMode ? '#FFD700' : undefined }}>
                Sort by
              </InputLabel>
              <Select
                labelId="sort-option-label"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                label="Sort by"
                style={{ color: darkMode ? '#FFD700' : undefined }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: darkMode ? '#1e1e1e' : '#fff',
                      color: darkMode ? '#FFD700' : '#000',
                    },
                  },
                }}
              >
                <MenuItem value="highestTVL">Highest TVL</MenuItem>
                <MenuItem value="none">None</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Token Table */}
          {loading ? (
            <Box display="flex" flexDirection="column" gap={2} mt={5}>
              {[...Array(5)].map((_, index) => (
                <Skeleton variant="rectangular" height={60} key={index} />
              ))}
            </Box>
          ) : error ? (
            <Typography variant="h6" color="error" align="center">
              <ErrorIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
              Failed to load token data.
            </Typography>
          ) : displayedTokens.length === 0 ? (
            <Typography variant="h6" align="center">
              No tokens found.
            </Typography>
          ) : (
            <Box className="responsive-table-container">
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: darkMode ? '#121212' : '#fff',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  borderRadius: '8px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow className="token-table-header">
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell align="right" className="volume-column">
                        Volume
                      </TableCell>
                      <TableCell align="right" className="tvl-column">
                        TVL
                      </TableCell>
                      <TableCell align="right" className="apr-column">
                        APR
                      </TableCell>
                      <TableCell align="right" className="price-column">
                        Price USD
                      </TableCell>
                      {/* Removed Transactions Column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTokens.map((token) => {
                      const metrics = calculateMetrics(token);
                      const isExpanded = expandedToken === token.id;
                      const symbol = getTradingViewSymbol(token);

                      return (
                        <React.Fragment key={token.id}>
                          {/* Main Token Row */}
                          <TableRow
                            hover
                            onClick={() => handleRowClick(token.id)}
                            className="token-table-row"
                            sx={{
                              cursor: 'pointer',
                              transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
                              '&:hover': {
                                backgroundColor: darkMode
                                  ? 'rgba(255, 215, 0, 0.05)'
                                  : '#f5f5f5',
                                transform: 'scale(1.02)',
                                boxShadow: darkMode
                                  ? '0 4px 8px rgba(255, 215, 0, 0.2)'
                                  : '0 4px 8px rgba(0,0,0,0.1)',
                              },
                            }}
                          >
                            <TableCell>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering row click
                                  handleRowClick(token.id);
                                }}
                                aria-label={isExpanded ? 'Collapse details' : 'Expand details'}
                                className="expand-icon"
                                id={`expand-button-${token.id}`} // Adding unique ID for the expand button
                              >
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <Avatar
                                  src={token.logoURI}
                                  alt={`${token.name} logo`}
                                  className="token-avatar"
                                  loading="lazy"
                                  id={`avatar-${token.id}`} // Adding unique ID for the avatar
                                />
                                <Box ml={2}>
                                  <Typography variant="subtitle1" className="token-name" id={`token-name-${token.id}`}>
                                    {token.name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" id={`token-symbol-${token.id}`}>
                                    {token.symbol.toUpperCase()}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="right" className="metric volume-column" id={`volume-${token.id}`}>
                              {metrics.totalVolume}
                            </TableCell>
                            <TableCell align="right" className="metric tvl-column" id={`tvl-${token.id}`}>
                              {metrics.totalTVL}
                            </TableCell>
                            <TableCell align="right" className="metric apr-column" id={`apr-${token.id}`}>
                              {metrics.apr}
                            </TableCell>
                            <TableCell align="right" className="metric price-column" id={`price-${token.id}`}>
                              {metrics.onchainPrice}
                            </TableCell>
                            {/* Removed Transactions Column */}
                          </TableRow>

                          {/* Collapsible Details Row */}
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6} // Adjust colspan based on the number of columns
                            >
                              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Box className="collapse-content" p={2} id={`collapse-content-${token.id}`}>
                                  {/* Organized Token Information */}
                                  <Box mb={2} id={`token-info-${token.id}`}>
                                    <Typography variant="h6" gutterBottom>
                                      <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                      Token Information
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    <Grid container spacing={1}>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`decimals-${token.id}`}>
                                          <strong>Decimals:</strong> {token.decimals}
                                        </Typography>
                                      </Grid>
                                      {token.description && (
                                        <Grid item xs={12}>
                                          <Typography variant="body2" id={`description-${token.id}`}>
                                            <strong>Description:</strong> {token.description}
                                          </Typography>
                                        </Grid>
                                      )}
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`dex-${token.id}`}>
                                          <strong>DEX:</strong> {determineDEX(token)}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" id={`token-id-${token.id}`}>
                                          <strong>Token ID:</strong> {formatAddress(token.id)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>

                                  {/* Pool Detail Buttons */}
                                  <Box mb={2} display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                                    {/* Elexium Pools Details Button */}
                                    {token.elexiumPools.length > 0 && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={
                                          expandedSections[token.id]?.elexiumPools ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        }
                                        onClick={() => handleSectionToggle(token.id, 'elexiumPools')}
                                        sx={{
                                          backgroundColor: darkMode ? '#FFD700' : 'primary.main',
                                          color: darkMode ? '#000' : '#fff',
                                          '&:hover': {
                                            backgroundColor: darkMode ? '#FFC700' : 'primary.dark',
                                          },
                                        }}
                                        id={`elexium-pools-button-${token.id}`} // Adding unique ID for the button
                                        aria-expanded={expandedSections[token.id]?.elexiumPools ? 'true' : 'false'}
                                        aria-controls={`elexium-pools-section-${token.id}`}
                                      >
                                        {expandedSections[token.id]?.elexiumPools ? 'Hide Elexium Pools' : 'Show Elexium Pools'}
                                      </Button>
                                    )}

                                    {/* Ayin Pools Details Button */}
                                    {token.ayinPools.length > 0 && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={
                                          expandedSections[token.id]?.ayinPools ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        }
                                        onClick={() => handleSectionToggle(token.id, 'ayinPools')}
                                        sx={{
                                          backgroundColor: darkMode ? '#FFD700' : 'primary.main',
                                          color: darkMode ? '#000' : '#fff',
                                          '&:hover': {
                                            backgroundColor: darkMode ? '#FFC700' : 'primary.dark',
                                          },
                                        }}
                                        id={`ayin-pools-button-${token.id}`} // Adding unique ID for the button
                                        aria-expanded={expandedSections[token.id]?.ayinPools ? 'true' : 'false'}
                                        aria-controls={`ayin-pools-section-${token.id}`}
                                      >
                                        {expandedSections[token.id]?.ayinPools ? 'Hide Ayin Pools' : 'Show Ayin Pools'}
                                      </Button>
                                    )}

                                    {/* Transactions Button */}
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      startIcon={
                                        expandedSections[token.id]?.transactions ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )
                                      }
                                      onClick={() => handleTransactionsClick(token.id)}
                                      sx={{
                                        backgroundColor: darkMode ? '#FFD700' : 'secondary.main',
                                        color: darkMode ? '#000' : '#fff',
                                        '&:hover': {
                                          backgroundColor: darkMode ? '#FFC700' : 'secondary.dark',
                                        },
                                      }}
                                      id={`transactions-button-${token.id}`} // Adding unique ID for the button
                                      aria-expanded={expandedSections[token.id]?.transactions ? 'true' : 'false'}
                                      aria-controls={`transactions-section-${token.id}`}
                                    >
                                      {expandedSections[token.id]?.transactions ? 'Hide Transactions' : 'Show Transactions'}
                                    </Button>
                                  </Box>

                                  {/* Elexium Pools Section */}
                                  {token.elexiumPools.length > 0 && (
                                    <Collapse
                                      in={expandedSections[token.id]?.elexiumPools}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box mb={2} className="elexium-pools-section" id={`elexium-pools-section-${token.id}`}>
                                        <Typography variant="h6" gutterBottom>
                                          <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                          Elexium Pools
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={2}>
                                          {token.elexiumPools.map((pool, index) => (
                                            <Grid item xs={12} key={`${token.id}-elexium-pool-${index}`}>
                                              <Box mb={2} p={2} sx={{ border: '1px solid #ccc', borderRadius: '8px' }}>
                                                {/* Display Token Logos Instead of Text */}
                                                <Typography variant="subtitle1" gutterBottom>
                                                  <Avatar
                                                    src={pool.token0.logo || '/path-to-default-logo.png'}
                                                    alt={pool.token0.symbol}
                                                    className="pool-icon"
                                                    sx={{ marginRight: '4px' }}
                                                  />
                                                  <Avatar
                                                    src={pool.token1.logo || '/path-to-default-logo.png'}
                                                    alt={pool.token1.symbol}
                                                    className="pool-icon"
                                                    sx={{ marginRight: '8px' }}
                                                  />
                                                  {pool.token0.symbol} / {pool.token1.symbol}
                                                </Typography>

                                                <Grid container spacing={1}>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Pair:</strong> {pool.token0.symbol} / {pool.token1.symbol}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Pool Address:</strong> {formatAddress(pool.poolAddress)}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Pool ID:</strong> {pool.id}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Token 0 Address:</strong> {formatAddress(pool.token0.address)}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Token 1 Address:</strong> {formatAddress(pool.token1.address)}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>TVL:</strong> {pool.tvl ? `$${parseFloat(pool.tvl).toLocaleString()}` : 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Volume:</strong> {pool.volume ? parseFloat(pool.volume).toLocaleString() : 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>USD Price:</strong> {pool.onchainPrice ? `$${parseFloat(pool.onchainPrice).toFixed(2)}` : 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  {pool.gauge && (
                                                    <>
                                                      <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">
                                                          <strong>APR:</strong> {pool.gauge.apr ? `${(parseFloat(pool.gauge.apr) * 100).toFixed(2)}%` : 'N/A'}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">
                                                          <strong>Gauge Address:</strong> {formatAddress(pool.gauge.address)}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">
                                                          <strong>Internal Bribe Address:</strong> {formatAddress(pool.gauge.internalBribeAddress)}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">
                                                          <strong>External Bribe Address:</strong> {formatAddress(pool.gauge.externalBribeAddress)}
                                                        </Typography>
                                                      </Grid>
                                                    </>
                                                  )}
                                                </Grid>
                                              </Box>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  )}

                                  {/* Ayin Pools Section */}
                                  {token.ayinPools.length > 0 && (
                                    <Collapse
                                      in={expandedSections[token.id]?.ayinPools}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box mb={2} className="ayin-pools-section" id={`ayin-pools-section-${token.id}`}>
                                        <Typography variant="h6" gutterBottom>
                                          <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                          Ayin Pools
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        <Grid container spacing={2}>
                                          {token.ayinPools.map((pool, index) => (
                                            <Grid item xs={12} key={`${token.id}-ayin-pool-${index}`}>
                                              <Box mb={2} p={2} sx={{ border: '1px solid #ccc', borderRadius: '8px' }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                  {/* Display Token Logos Instead of "Pool {index + 1}" */}
                                                  <Avatar
                                                    src={pool.token0.logo || '/path-to-default-logo.png'}
                                                    alt={pool.token0.symbol}
                                                    className="pool-icon"
                                                    sx={{ marginRight: '4px' }}
                                                  />
                                                  <Avatar
                                                    src={pool.token1.logo || '/path-to-default-logo.png'}
                                                    alt={pool.token1.symbol}
                                                    className="pool-icon"
                                                    sx={{ marginRight: '8px' }}
                                                  />
                                                  {pool.token0.symbol} / {pool.token1.symbol}
                                                </Typography>
                                                <Grid container spacing={1}>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Factory:</strong> {formatAddress(pool.factory)}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Pool Address:</strong> {formatAddress(pool.address)}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Name:</strong> {pool.name || 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Symbol:</strong> {pool.symbol || 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Decimals:</strong> {pool.decimals}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Total Supply:</strong> {pool.totalSupply}
                                                    </Typography>
                                                  </Grid>
                                                  {pool.description && (
                                                    <Grid item xs={12}>
                                                      <Typography variant="body2">
                                                        <strong>Description:</strong> {pool.description}
                                                      </Typography>
                                                    </Grid>
                                                  )}
                                                  {/* Ayin Pool Tokens */}
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Token0 Address:</strong> {formatAddress(pool.token0.address)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>Symbol:</strong> {pool.token0.symbol}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>Name:</strong> {pool.token0.name}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>USD Price:</strong> {pool.token0.onchainPrice ? `$${parseFloat(pool.token0.onchainPrice).toFixed(2)}` : 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2">
                                                      <strong>Token1 Address:</strong> {formatAddress(pool.token1.address)}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>Symbol:</strong> {pool.token1.symbol}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>Name:</strong> {pool.token1.name}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      <strong>USD Price:</strong> {pool.token1.onchainPrice ? `$${parseFloat(pool.token1.onchainPrice).toFixed(2)}` : 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  )}

                                  {/* Transactions Section */}
                                  {expandedSections[token.id]?.transactions && (
                                    <Collapse
                                      in={expandedSections[token.id]?.transactions}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box mb={2} className="transactions-section" id={`transactions-section-${token.id}`}>
                                        <Typography variant="h6" gutterBottom>
                                          <SwapHorizIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                          Transactions
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {/* Render the TokenTransactionAnalyzer Inline */}
                                        <TokenTransactionAnalyzer tokenId={token.id} />
                                      </Box>
                                    </Collapse>
                                  )}

                                  {/* TradingView Chart */}
                                  <Box mb={2} id={`trading-chart-${token.id}`}>
                                    <Typography variant="h6" gutterBottom>
                                      <InfoIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                      Trading Chart
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    <TradingViewChart symbol={symbol} />
                                  </Box>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              {totalPages > 1 && (
                <Box display="flex" justifyContent="center" mt={3}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    id="pagination-component" // Adding unique ID for the pagination
                    aria-label="Token list pagination"
                  />
                </Box>
              )}
            </Box>
          )}
        </Container>
      </div>
    </ThemeProvider>
  ); // Closing the return statement
}; // Closing the TokenListPools function

export default TokenListPools; // Exporting the TokenListPools component
